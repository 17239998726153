import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import UserLoginPage from '../pages/UserLoginPage';
import AttendancePage from '../pages/AttendancePage';
import AppointmentPage from '../pages/AppointmentPage';
import { Navbar } from '../components/common';
import RewardsPage from '../pages/RewardsPage';
import CommissionsPage from '../pages/CommissionsPage';
import LeaderboardPage from '../pages/LeaderboardPage';
import PrestigeAcademyPage from '../pages/PrestigeAcademyPage';
import LeavePage from '../pages/LeavePage';
import RestDayPage from '../pages/RestDayPage';
import OvertimePage from '../pages/OvertimePage';
import ScheduleAdjustmentPage from '../pages/ScheduleAdjustmentPage';
import OfficialBusinessPage from '../pages/OfficialBusinessPage';
import DashboardPage from '../pages/DashboardPage';
import PointsPage from '../pages/PointsPage';
import NewFormsPage from '../pages/NewFormsPage';
import RewardsCatalogPage from '../pages/RewardsCatalogPage';
import CheckinPage from '../pages/CheckinPage';
import SalesPage from '../pages/SalesPage';
import ProfilePage from '../pages/ProfilePage';

import '../index.css'

const AppRoute = () => {

  const SidebarLayout = () => (
    <>
      <Navbar />
      <Outlet />
    </>
  );

  return (
    
    <>
    
      <Routes>
        <Route path="/" element={<UserLoginPage />} />
        <Route path="/login" element={<UserLoginPage />} />
      </Routes>
      <div id='whole'>
          <Routes>
            <Route element={<SidebarLayout/>}>
            <Route path="/sale" element={<SalesPage />} />
                <Route path="/attendance" element={<AttendancePage />} />
                <Route path="/appointment" element={<AppointmentPage />} />
                <Route path="/rewards" element={<RewardsPage />} />
                <Route path="/commissions" element={<CommissionsPage />} />
                <Route path="/leaderboard" element={<LeaderboardPage />} />
                <Route path="/prestigeacademy" element={<PrestigeAcademyPage />} />
                <Route path="/leave" element={<LeavePage />} />
                <Route path="/restday" element={<RestDayPage />} />
                <Route path="/overtime" element={<OvertimePage />} />
                <Route path="/scheduleadjustment"element={<ScheduleAdjustmentPage />}/>
                <Route path="/officialbusiness" element={<OfficialBusinessPage />}/>
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/rewardscatalog" element={<RewardsCatalogPage /> } />
                <Route path="/points" element={<PointsPage /> } />
                <Route path="/download" element={<NewFormsPage /> } />
                <Route path="/checkin" element={<CheckinPage /> } />
                <Route path="/profile" element={<ProfilePage /> } />
            </Route>
          </Routes>
      </div>
    </>
  );
};

export default AppRoute;
