import React, { useState, useEffect } from "react";
import { Form, Select, DatePicker, Table, Avatar, Tooltip } from 'antd';
import axios from 'axios';
import Confetti from 'react-confetti'; // Import Confetti component
import CarIcon from '../assets/award-car.png'; // Import car icon
import MidYearTravelIcon from '../assets/award-mid-year.png'; // Import mid-year travel icon
import YearEndTravelIcon from '../assets/award-year-end.png';
import FocusedProjectIcon from '../assets/award-focused-project.png';
import "../styles/Leaderboard.css"
import { useNavigate } from 'react-router-dom';

import { Header } from "../components/common/"

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const LeaderboardPage = () => {
  const [form] = Form.useForm();
  const [selectedRole, setSelectedRole] = useState('Property Consultant');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [data, setData] = useState([]);
  const [loggedInUserId, setLoggedInUserId] = useState(null);
  const [showConfetti, setShowConfetti] = useState(true); // State to control confetti display

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const API_BASE_URL_API = process.env.REACT_APP_API_BASE_URL_API;

  const statusOptions = ['Property Consultant', 'Sales Manager', 'Sales Director', 'VP'];

  const handleOk = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/leaderboard`, {
        params: {
          role: selectedRole,
          from_date: fromDate,
          to_date: toDate
        }
      });
      setData(response.data.data); // Assuming data is nested under 'data' key
      setLoggedInUserId(response.data.loggedInUserId); // Set loggedInUserId from the response
      setShowConfetti(true); // Show confetti upon successful data retrieval
      setTimeout(() => setShowConfetti(false), 2000); // Hide confetti after 5 seconds
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    // Check if token is not present, redirect to login page
    if (!localStorage.getItem('token')) {
      navigate('/login'); // Redirect to login page
    } else {
      // Token is present, continue with the component logic
      fetchAppointmentData();
    }
  }, []);

  const fetchAppointmentData = () => {
    const storedToken = localStorage.getItem("token");

      if (!storedToken) {
        console.error("Token is not present in localStorage. Redirect or show an error message.");
        return;
      }
    }
  
  const rowClassName = (record) => {
    return record.user_id === loggedInUserId ? 'highlight-row' : '';
  };
  
  useEffect(() => {
    handleOk(); // Fetch data when component mounts or selectedRole, fromDate, or toDate changes
  }, [selectedRole, fromDate, toDate]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (text, record, index) => (index + 1).toLocaleString(),
    },
    {
      key: 'avatar',
      render: (text, record) => (
        <Avatar src={`${API_BASE_URL_API}/upload/admin_images/${record.profile_photo_path}`} /> // Construct the complete URL
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (selectedRole === 'VP' ? record.department_name.toUpperCase() : record.name.toUpperCase()),
    },
    {
      title: 'Production',
      dataIndex: 'production',
      key: 'production',
      render: (data) => data.toLocaleString(),
    },
    {
      title: 'Awards',
      dataIndex: 'awards',
      key: 'awards',
      render: (userAwards) => (
        <div className="awards-column">
          {userAwards && userAwards.length > 0 ? (
            userAwards.map((award) => (
              <div key={award.id}>
                {award.car != 0 && (
                  <Tooltip title="Car Award">
                    <img src={CarIcon} alt="Car" width="40" height="40" />
                  </Tooltip>
                )}
                {award.mid_year_travel != 0 && (
                  <Tooltip title="Mid-Year Travel Award">
                    <img src={MidYearTravelIcon} alt="Mid-Year Travel" width="40" height="40" />
                  </Tooltip>
                )}
                {award.year_end_travel != 0 && (
                  <Tooltip title="Year-End Travel Award">
                    <img src={YearEndTravelIcon} alt="Year-End Travel" width="40" height="40" />
                  </Tooltip>
                )}
                {award.focused_projects && award.focused_projects.length > 0 && (
                  <Tooltip title="Focused Project Award">
                    {award.focused_projects.map((project) => (
                      <div key={project.id}>
                        {project.project_logo ? (
                          <img
                            src={`${API_BASE_URL_API}/storage/projects/${project.project_logo}`}
                            alt={project.name}
                            width="40"
                            height="40"
                          />
                        ) : (
                          <span>{project.name}</span>
                        )}
                      </div>
                    ))}
                  </Tooltip>
                )}
              </div>
            ))
          ) : null}
        </div>
      ),
      // Add this condition to show/hide the column
      shouldCellUpdate: (record) => record.awards && record.awards.length > 0,
    },
  ];

  return (
    <div id="body">
      <Header pageName ="Leaderboard"/>

      <div id='page'>
        <Form
          form={form}
          onFinish={handleOk}
          {...formItemLayout}
          labelWrap
          variant="filled"
          style={{
            maxWidth: 600,
            marginTop: "5%",
          }}
        >
          <Form.Item
            label="Role"
            name="status"
            rules={[
              {
                required: true,
                message: "Role is required.",
              },
            ]}
          >
            <Select onChange={(value) => setSelectedRole(value)}>
              {statusOptions.map((status) => (
                <Select.Option key={status} value={status}>
                  {status}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="From Date"
            name="fromDate"
          >
            <DatePicker onChange={(date) => setFromDate(date ? date.format('YYYY-MM-DD') : null)} />
          </Form.Item>

          <Form.Item
            label="To Date"
            name="toDate"
          >
            <DatePicker onChange={(date) => setToDate(date ? date.format('YYYY-MM-DD') : null)} />
          </Form.Item>

        </Form>

        <div style={{ marginTop: '16px' }}> 
          <Table columns={columns} dataSource={data} rowClassName={rowClassName} />
        </div>

        {/* Confetti component */}
        {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
      </div>
    </div>
  );
};

export default LeaderboardPage;
