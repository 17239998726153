import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, TimePicker, DatePicker, message, Descriptions, Checkbox, Space, Table, Popconfirm } from 'antd';
import { DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'; 
import { Header } from '../components/common';
import { useNavigate } from 'react-router-dom';

import "../index.css"

const { TextArea } = Input;

const workScheduleItems = [  //Sample data
  {
    key: '1',
    label: 'Payroll Type',
    children: 'Semi-Monthly',
  },
  {
    key: '2',
    label: 'Schedule Type',
    children: 'Normal Shift',
  },
  {
    key: '3',
    label: 'Total Expected Hours',
    children: '9',
  },
];

const ScheduleAdjustmentPage = () => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if token is not present, redirect to login page
    if (!localStorage.getItem('token')) {
      navigate('/login'); // Redirect to login page
    } else {
      // Token is present, continue with the component logic
      fetchAppointmentData();
    }
  }, []);

  const fetchAppointmentData = () => {
    const storedToken = localStorage.getItem("token");

      if (!storedToken) {
        console.error("Token is not present in localStorage. Redirect or show an error message.");
        return;
      }
    }

  const newWorkScheduleColumns = [
    {
      title: 'Day',
      dataIndex: 'day',
      key: 'day',
      align: 'center',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
    },
    {
      title: 'Shift Start',
      dataIndex: 'shiftStart',
      key: 'shiftStart',
      align: 'center',
      render: (_) => (
        <TimePicker />
      ),
    },
    {
      title: 'Break Start',
      dataIndex: 'breakStart',
      key: 'breakStart',
      align: 'center',
      render: (_) => (
        <TimePicker />
      ),
    },
    {
      title: 'Break End',
      dataIndex: 'breakEnd',
      key: 'breakEnd',
      align: 'center',
      render: (_) => (
        <TimePicker />
      ),
    },
    {
      title: 'Shift End',
      dataIndex: 'shiftEnd',
      key: 'shiftEnd',
      align: 'center',
      render: (_) => (
        <TimePicker />
      ),
    },
    {
      title: 'Shift End',
      dataIndex: 'shiftEnd',
      key: 'shiftEnd',
      align: 'center',
      render: (_) => (
        <TimePicker />
      ),
    },
    {
      title: 'Make Rest Day',
      dataIndex: 'rest',
      key: 'rest',
      align: 'center',
      render: (_) => (
        <Checkbox></Checkbox> // TODO: add onChange attribute
      ),
    },
    {
      title: 'No Break',
      dataIndex: 'break',
      key: 'break',
      align: 'center',
      render: (_) => (
        <Checkbox></Checkbox>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        newWorkScheduleData.length >= 1 ? (
          <Space size="middle">
            {/* TODO: handleCopy */}
          <a><CopyOutlined /></a> 
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <a><DeleteOutlined /></a>
          </Popconfirm>
        </Space>
        ) : null
      ),
    },
  ];

  const [newWorkScheduleData, setnewWorkScheduleData] = useState([ //dummy date
  {
    key: '1',
    day: 'Thursday',
    date: '2021-07-08',
  },
]);

  const handleDelete = (key) => {
    const newData = newWorkScheduleData.filter((item) => item.key !== key);
    setnewWorkScheduleData(newData);
  };

  const onFinish = (values) => {
    setFormData(values);
    message.success('Form submitted successfully!');
  };

  const [isOpen, setIsOpen] = useState(false);
  
  function toggle() {
    setIsOpen((isOpen) => !isOpen);
  }
  
  return (
    <div id="body">
      <Header pageName="Schedule Adjustment Form"/>
      <div id='page'>
      <h2> Work Schedule</h2>
      <div>
        <Descriptions items={workScheduleItems} />
      </div>
      <h2> Effectivity Date</h2>
      <Form  form={form} onFinish={onFinish} >
        <Form.Item 
         label="Duration"
         name="Duration" 
        > 
        < DatePicker.RangePicker />
        </Form.Item>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '2%', justifyContent: 'space-between'}}>
          <Form.Item label="Shift Start" name="Shift Start" >
            <TimePicker />
          </Form.Item>

          <Form.Item label="Break Start" name="Break Start" >
            <TimePicker />
          </Form.Item>

          <Form.Item label="Break End" name="Break End" >
            <DatePicker />
          </Form.Item>

          <Form.Item label="Shift End" name="Shift End">
            <DatePicker />
          </Form.Item>

          <Button type="primary" onClick={toggle}>
            Add Dates
          </Button>

      </div>


        {/* <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
          <div style={{ marginRight: '8px', display: 'inline-block' }}>
            <Button type="primary" htmlType="submit">
              Proceed
            </Button>
          </div>
          <Link to="/overtime">
            <Button>
              Back
            </Button>
          </Link>
        </Form.Item> */}

      </Form>

      {isOpen && <div>
        
        <h2> New Work Schedule</h2>
        {/* TODO change dataSource  */}
        <Table columns={newWorkScheduleColumns} dataSource={newWorkScheduleData} pagination={{position:['none', 'none']}} style={{marginBottom: '1%'}}/>  
        <Form.Item label="Reason">
          <TextArea rows={3} />
        </Form.Item>
        <div id='start-end'>
          <Button type="primary" htmlType="submit">
              Apply
          </Button>
        </div>
      </div>}
      
      
      
      </div>
    </div>
  );
};

export default ScheduleAdjustmentPage;
