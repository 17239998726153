import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router-dom';

import "../index.css"

import { Header } from "../components/common/"

const RewardsPage = () => {

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [rewardsData, setRewardsData] = useState([]);
    const searchInput = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
      // Check if token is not present, redirect to login page
      if (!localStorage.getItem('token')) {
        navigate('/login'); // Redirect to login page
      } else {
        // Token is present, continue with the component logic
        fetchAppointmentData();
      }
    }, []);
  
    const fetchAppointmentData = () => {
      const storedToken = localStorage.getItem("token");
  
        if (!storedToken) {
          console.error("Token is not present in localStorage. Redirect or show an error message.");
          return;
        }
      }

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    useEffect(() => {
      const fetchData = async () => {
          try {
              const storedToken = localStorage.getItem("token");

              if (!storedToken) {
                  console.error("Token is not present in localStorage. Redirect or show an error message.");
                  return;
              }

              const headers = {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${storedToken}`,
              };

              const response = await fetch(`${API_BASE_URL}/rewards/delivery`, { headers });
              const data = await response.json();

              // Check for the error key in the response
              if (data.error) {
                  console.error('Error from API:', data.error);
                  return;
              }

              // Assuming your API response includes image URLs under 'image_url' key
              const updatedData = data.data.map(item => ({
                ...item,
                img: <img src={item.catalog.image_url} alt="Product Image" style={{ maxWidth: '100px' }} />,
                date: formatDate(item.created_at) // Format date here
            }));

              setRewardsData(updatedData);
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      };

      fetchData();
  }, []);

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const options = { timeZone: 'Asia/Manila' };
    return dateObj.toLocaleDateString('en-US', options); // Adjust the format as needed
};


    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
          <div
            style={{
              padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                marginBottom: 8,
                display: 'block',
              }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                  width: 90,
                }}
              >
                Search
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                  width: 90,
                }}
              >
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({
                    closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
                }}
              >
                Filter
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  close();
                }}
              >
                close
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#1677ff' : undefined,
            }}
          />
        ),
        onFilter: (value, record) =>
          record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },
        render: (text) =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0,
              }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });

     
    const columns = [
        {
          title: 'Image',
          dataIndex: 'img',
          key: 'img',
          width: '15%',
          
        },
        {
          title: 'Product',
          dataIndex: ['catalog', 'name'],
          key: 'product',
          width: '15%',
          filterMultiple: false,
          ...getColumnSearchProps(),
        },
        {
            title: 'Product Code',
            dataIndex: ['catalog', 'product_code'],
            key: 'prodCode',
            width: '15%',
          },
        {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'qty',
        width: '10%',
        onFilter: (value, record) => record.name.indexOf(value) === 0,
        sorter: (a, b) => a.name.length - b.name.length,
        },  
        {
          title: 'Total Points',
          dataIndex: 'total_points',
          key: 'totalpts',
          width: '10%',
          onFilter: (value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            responsive: ["md"],
          // filters: [
          //   {
          //     text: "Approved",
          //     value: "Approved",
          //   },
          //   {
          //     text: "Declined",
          //     value: "Declined",
          //   }
          // ],
        },  
        {
            title: 'Delivery Status',
            dataIndex: 'delivery_status',
            key: 'deliveryStat',
            width: '15%',
            },  
            
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '15%',
            sorter: (a, b) => a.status.length - b.status.length,
            sortDirections: ['descend', 'ascend'],
        },    
            
    ];

    return (
      <div id="body">
        <Header pageName ="My Rewards"/>
        <div id='page'>
          <Table
          //   components={components}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={rewardsData}
            columns={columns}
          />
        </div>
      </div>
    )
}

export default RewardsPage

