import React, { useState, useEffect } from "react";
import { Card, Input, Spin } from "antd";
import ReactPlayer from "react-player/youtube";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import "../styles/PrestigeAcademy.css";
import { Header } from "../components/common/";

const { Meta } = Card;
const { Search } = Input;

const PrestigeAcademy = () => {
  const [videos, setVideos] = useState(null); // Set initial state to null

  const navigate = useNavigate();

  useEffect(() => {
    // Check if token is not present, redirect to login page
    if (!localStorage.getItem('token')) {
      navigate('/login'); // Redirect to login page
    } else {
      // Token is present, continue with the component logic
      fetchAppointmentData();
    }
  }, []);

  const fetchAppointmentData = () => {
    const storedToken = localStorage.getItem("token");

      if (!storedToken) {
        console.error("Token is not present in localStorage. Redirect or show an error message.");
        return;
      }
    }

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    async function fetchVideos() {
      try {
        const storedToken = localStorage.getItem("token");
        if (!storedToken) {
          console.error("Token is not present in localStorage. Redirect or show an error message.");
          return;
        }
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        };
        const response = await fetch(`${API_BASE_URL}/academy`, { headers });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json(); // Parse JSON data from response
        setVideos(data);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    }
    fetchVideos();
  }, []);

  return (
    <div id="body">
      <Header pageName="Prestige Academy" textColor="#A98F4E" />
      <div id="page">
        <h1
          style={{
            fontSize: "30px",
            color: "#A98F4E",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Courses
        </h1>
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "5%", marginTop:"2%"}}>
          <Search placeholder="Search" style={{ width: "70%" }} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "20px",
          }}
        >
          {videos === null && <Spin tip="Loading" size="large">
        <div className="content" />
      </Spin>} {/* Show loading message while fetching */}
          {videos && videos.length > 0 ? (
            videos.map((video) => (
              <Card
                key={video.id}
                className="course-card"
                style={{ width: 300 }}
              >
                <ReactPlayer
                  url={video.video_link}
                  controls
                  width="100%"
                  height="180px"
                />
                <Meta title={video.title} description={video.description} />
              </Card>
            ))
          ) : (
            videos !== null && <p>No videos found</p> 
          )}
        </div>
      </div>
    </div>
  );
};

export default PrestigeAcademy;

