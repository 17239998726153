import React, { useState, useEffect } from 'react';
import { Form, Input, DatePicker, Select, Upload, Button, Table, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import "../index.css"

import { Header } from "../components/common/"

const { Option } = Select;

const LeavePage = () => {
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [attachment, setAttachment] = useState(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    // Check if token is not present, redirect to login page
    if (!localStorage.getItem('token')) {
      navigate('/login'); // Redirect to login page
    } else {
      // Token is present, continue with the component logic
      fetchAppointmentData();
    }
  }, []);

  const fetchAppointmentData = () => {
    const storedToken = localStorage.getItem("token");

      if (!storedToken) {
        console.error("Token is not present in localStorage. Redirect or show an error message.");
        return;
      }
    }

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const onFinish = async (values) => {
    const storedToken = localStorage.getItem("token");
  
    if (!storedToken) {
      console.error("Token is not present in localStorage. Redirect or show an error message.");
      return;
    }
  
    const formData = new FormData();
    formData.append('leave_type', values.leave_type);
    formData.append('reason', values.reason);
    tableData.forEach((date, index) => {
      formData.append(`dates[${index}][date]`, moment(date.date).format('YYYY-MM-DD'));
      formData.append(`dates[${index}][duration]`, date.duration);
    });
  
    // Append attachment only if it's provided
    if (attachment) {
      formData.append('attachment', attachment);
    }
  
    const headers = {
      'Authorization': `Bearer ${storedToken}`,
      // Remove Content-Type header to allow Axios to set it automatically
    };
  
    try {
      const response = await axios.post(`${API_BASE_URL}/attendance/leave`, formData, { headers });
      navigate('/attendance');
      message.success('Leave application submitted successfully!');
    } catch (error) {
      if (error.response && error.response.status === 422 && error.response.data && error.response.data.message === 'Leave application exceeds leave credits') {
        message.error('Leave application exceeds leave credits. Please adjust your leave dates or apply for fewer days.');
      } else {
        message.error('Failed to submit leave application. Please try again later.');
      }
    }
  };
  

  const onDateChange = (date, key) => {
    const updatedTableData = tableData.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          date: date ? date.format('YYYY-MM-DD') : null,
        };
      }
      return item;
    });
    setTableData(updatedTableData);
  };

  const onDurationChange = (value, key) => {
    const updatedTableData = tableData.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          duration: value,
        };
      }
      return item;
    });
    setTableData(updatedTableData);
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => (
        <DatePicker onChange={(date) => onDateChange(date, record.key)} />
      ),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      render: (text, record) => (
        <Select
          onChange={(value) => onDurationChange(value, record.key)}
          value={record.duration}
        >
          <Option value="Whole Day">Whole Day</Option>
          <Option value="Half Day">Half Day</Option>
        </Select>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Button type="link" onClick={() => handleRemove(record.key)}>
          Remove
        </Button>
      ),
    },
  ];

  const handleAdd = () => {
    const newData = {
      key: moment().valueOf(), // Generate unique key
      date: moment().format('YYYY-MM-DD'),
      duration: 'Whole Day',
    };
    setTableData([...tableData, newData]);
  };

  const handleRemove = (key) => {
    setTableData(tableData.filter((item) => item.key !== key));
  };

  const handleFileChange = (info) => {
    if (info.fileList.length > 1) {
      // Only keep the last file if multiple files are selected
      info.fileList.splice(0, info.fileList.length - 1);
    }
  
    if (info.fileList.length > 0) {
      setAttachment(info.fileList[0].originFileObj);
    } else {
      setAttachment(null); // Set attachment to null if no file is uploaded
    }
  };

  useEffect(() => {
    form.setFieldsValue({ dates: tableData });
  }, [tableData, form]);

  return (
    <div id="body">
        <Header pageName ="Leave Form"/>
        <div id="page">
          <Form form={form} onFinish={onFinish} layout="vertical" encType="multipart/form-data">
            <Form.Item name="leave_type" label="Leave Type" rules={[{ required: true, message: 'Please select leave type' }]}>
              <Select>
                <Option value="Vacation">Vacation</Option>
                <Option value="Sick">Sick</Option>
                <Option value="Bereavement">Bereavement</Option>
              </Select>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
              <Button onClick={handleAdd}>
                Add Date
              </Button>
            </Form.Item>

            <Table
              dataSource={tableData}
              columns={columns}
              pagination={false}
              rowKey={(record) => record.key}
            />
            <Form.Item name="attachment" label="Attachment">
              <Upload onChange={handleFileChange} accept=".pdf,.docx" beforeUpload={() => false}>
                <Button icon={<UploadOutlined />}>Upload File</Button>
              </Upload>
            </Form.Item>

            <Form.Item name="reason" label="Reason" rules={[{ required: true, message: 'Please provide a reason' }]}>
              <Input.TextArea />
            </Form.Item>

            <div id='start-end'>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
    </div>
  );
};

export default LeavePage;
