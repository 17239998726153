import React, { useState, useEffect, useRef } from "react";
import { Table, Input, Button, Space, Tag, DatePicker, Spin } from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../services/useAuth";
import { useNavigate } from "react-router-dom";
import { Header } from "../components/common/"

const SalesPage = () => {
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [userRole, setUserRole] = useState("");
    const { user } = useAuth();
    const navigate = useNavigate();
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (user) {
            setUserRole(user.roles[0].name);
            fetchAppointmentData();
        }
    }, [user, fromDate, toDate]);

    const fetchAppointmentData = () => {
        const storedToken = localStorage.getItem("token");
        if (!storedToken) {
            navigate("/login");
            return;
        }

        let params = { from_date: fromDate, to_date: toDate };
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
        };

        fetch(`${API_BASE_URL}/sale`, { headers, params })
            .then((response) => response.json())
            .then((data) => {
                setAppointments(data.data);
                setLoading(false);
                if (data.message) {
                    toast.success(data.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                const errorMessage = error.response?.data?.errors?.join(", ") || "Error fetching data.";
                toast.error(errorMessage);
            });
    };

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const formatNumber = (number) => {
      return new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      }).format(number);
  };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }}>
                {dataIndex === "rf_date" || dataIndex === "booked_date" ? (
                    <DatePicker.RangePicker
                        value={selectedKeys[0] && selectedKeys[1] ? [moment(selectedKeys[0]), moment(selectedKeys[1])] : []}
                        onChange={(dates) => {
                            setSelectedKeys(dates ? [dates[0].format("YYYY-MM-DD"), dates[1].format("YYYY-MM-DD")] : []);
                        }}
                        style={{ marginBottom: 8, display: "block" }}
                    />
                ) : (
                    <Input
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{ marginBottom: 8, display: "block" }}
                    />
                )}
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button type="link" size="small" onClick={close}>
                        Close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        render: (text) => (searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ""}
            />
        ) : (text))
    });

    const columns = [
        { title: "RF Date", dataIndex: "rf_date", key: "rf_date", ...getColumnSearchProps("rf_date") },
        { title: "Booked Date", dataIndex: "booked_date", key: "booked_date", ...getColumnSearchProps("booked_date") },
        { title: "Client", dataIndex: "client_name", key: "client_name", ...getColumnSearchProps("client_name") },
         {
      title: "Seller Name",
      dataIndex: ["seller", "name"],
      key: "seller",
      width: "15%",
      ...getColumnSearchProps(["seller", "name"]),
      sorter: (a, b) => {
        const nameA = a.seller ? a.seller.toLowerCase() : "";
        const nameB = b.seller ? b.seller.toLowerCase() : "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Sales Manager",
      dataIndex: ["sales_manager", "name"],
      key: "seller",
      width: "15%",
      ...getColumnSearchProps(["sales_manager", "name"]),
      sorter: (a, b) => {
        const nameA = a.seller ? a.seller.toLowerCase() : "";
        const nameB = b.seller ? b.seller.toLowerCase() : "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Sales Director",
      dataIndex: ["sales_director", "name"],
      key: "seller",
      width: "15%",
      ...getColumnSearchProps(["sales_director", "name"]),
      sorter: (a, b) => {
        const nameA = a.seller ? a.seller.toLowerCase() : "";
        const nameB = b.seller ? b.seller.toLowerCase() : "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["descend", "ascend"],
    },
    
        { title: "Project", dataIndex: "project", key: "project" },
        { title: "Tower", dataIndex: "tower", key: "tower" },
        { title: "Floor Number", dataIndex: "floor_number", key: "floor_number" },
        { title: "Unit", dataIndex: "unit_number", key: "unit_number" },
        { title: "Net Selling Price", dataIndex: "net_selling_price", key: "net_selling_price", render: formatNumber },
        { title: "Multiplier", dataIndex: "multiplier", key: "multiplier" },
        // { title: "Referrer", dataIndex: "referrer", key: "referrer" },
        // { title: "Collation Status", dataIndex: "collation_status", key: "collation_status", render: (status) => <Tag>{status}</Tag> },
        { title: "Receivables", dataIndex: "receivables", key: "receivables", render: formatNumber }, // Adjust this to match the backend response
        { title: "Status", dataIndex: "status", key: "status" },
    ];

    return (
      <div id="body">
           <Header pageName="Sales" />
            <ToastContainer />
            {/* <Space style={{ marginBottom: 16 }}>
                <DatePicker.RangePicker onChange={(dates) => { setFromDate(dates[0]?.format("YYYY-MM-DD")); setToDate(dates[1]?.format("YYYY-MM-DD")); }} />
                <Button onClick={fetchAppointmentData} icon={<SearchOutlined />}>Filter by Date</Button>
            </Space> */}
            {loading ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            ) : (
                <Table columns={columns} dataSource={appointments} rowKey="id" />
            )}
        </div>
    );
};

export default SalesPage;
