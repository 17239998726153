import React, { Component, Fragment } from 'react'

import UserLogin from '../components/common/UserLogin'


class UserLoginPage extends Component {
     componentDidMount(){
          window.scroll(0,0)
     }
     
     render() {

          const setUser = this.props.setUser;
          const user = this.props.user;

          return (
               <Fragment> 
                    

               <UserLogin setUser={setUser} user ={user} /> 
               
               
          </Fragment>
          )
     }
}

export default UserLoginPage
