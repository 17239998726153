import React, { useState, useEffect } from "react";
import {
  BellOutlined,
  UserOutlined,
  BellTwoTone,
  UsergroupAddOutlined,
  LogoutOutlined,
  LoginOutlined,
  TagOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Spin, Tooltip } from "antd";
import axios from "axios";

import { AddAppointmentModal, CheckInModal, CheckOutModal } from "../common";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../services/useAuth";

const Header = ({ pageName }) => {
  const [userData, setUserData] = useState({ userName: "", userRole: "" });
  const [loading, setLoading] = useState(true);
  const [towers, setTowers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [totalAwardedPoints, setTotalAwardedPoints] = useState(0);
  const [totalPointsDeducted, setTotalPointsDeducted] = useState(0);
  const [userStatus, setUserStatus] = useState({ hasCheckedIn: false, hasCheckedOut: false });
  const [isAddAppointmentModalOpen, setisAddAppointmentModalOpen] = useState(false);
  const [isCheckInModalOpen, setisCheckInModalOpen] = useState(false);
  const [isCheckOutModalOpen, setisCheckOutModalOpen] = useState(false);

  const { user } = useAuth();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/user-data`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      
      const userData = response.data;
      setTowers(userData.towers);
      setProjects(userData.projects);
      setAppointments(userData.appointments);
      setTotalAwardedPoints(userData.total_awarded_points);
      setTotalPointsDeducted(userData.total_points_deducted);
      setUserData({ userName: userData.userName, userRole: userData.userRole });
      
      setUserStatus({ hasCheckedIn: userData.hasCheckedIn, hasCheckedOut: userData.hasCheckedOut });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const onAddSuccess = () => {
    // You can add handling logic here if needed
  };

  const handleCheckIn = () => {
    setisCheckInModalOpen(true);
  };

  const handleCheckOut = () => {
    setisCheckOutModalOpen(true);
  };

  const handleCloseCheckInModal = () => {
    setisCheckInModalOpen(false);
    fetchUserData();
  };

  const handleCloseCheckOutModal = () => {
    setisCheckOutModalOpen(false);
    fetchUserData();
  };

  return (
    <header>
      <h1>{pageName}</h1>
      <div id="user-box">
        {loading ? (
          <Spin />
        ) : (
          <>
            <div id="header-box">
              {/* <div id="header-points">
                <Tooltip title="Points">
                  {totalAwardedPoints - totalPointsDeducted}
                </Tooltip>
              </div> */}
              <div id="header-buttons">
                <Tooltip title="Add Appointment">
                  <Button onClick={() => setisAddAppointmentModalOpen(true)} type="primary" shape="round"  icon={<PlusOutlined />}>
                  Add Appointment
                  
                  </Button>
                </Tooltip>
                {!userStatus.hasCheckedIn && (
                  <Tooltip title="Check In">
                    <Button
                onClick={handleCheckIn}
                type="secondary"
                shape="circle"
              >
                <LoginOutlined />
              </Button>
                  </Tooltip>
                )}
                {userStatus.hasCheckedIn && !userStatus.hasCheckedOut && (
                  <Tooltip title="Check Out">
                    <Button
                  onClick={handleCheckOut}
                  type="secondary"
                  shape="circle"
                >
                  <LogoutOutlined />
                </Button>
                  </Tooltip>
                )}
              </div>
            </div>
            <div id="user">
  <p id="user-name">{userData.userName}</p>
  <p id="user-role">{userData.userRole}</p>
</div>
          </>
        )}
        <AddAppointmentModal
          isOpen={isAddAppointmentModalOpen}
          onClose={() => setisAddAppointmentModalOpen(false)}
          projects={projects}
          towers={towers}
          onAddSuccess={onAddSuccess}
        />
        <CheckInModal
          isOpen={isCheckInModalOpen}
          onClose={handleCloseCheckInModal}
        />
        <CheckOutModal
          isOpen={isCheckOutModalOpen}
          onClose={handleCloseCheckOutModal}
        />
        <ToastContainer style={{ fontSize: "14px" }} />
      </div>
    </header>
  );
};

export default Header;
