import React, { Fragment, useState, useEffect, useContext } from "react";
import { Form, Modal, Input, DatePicker, Select, Button} from "antd";
import moment from 'moment';
import { useAuth } from "../../services/useAuth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
  }

const EditAppointmentModal = ({
    isOpen,
    onClose,
    currentAppointment,
    projects,
  towers,
  onEditSuccess, 
}) => {
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedTower, setSelectedTower] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [error, setError] = useState(null);
    const [form] = Form.useForm();
    const { user } = useAuth();
    const statusOptions = ['Closeable', 'Workable', 'For Booking', 'Fallout'];

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const handleOk = async () => {
        try {
          const values = await form.validateFields();

          
    

          const dateValue = values.date && values.date.toISOString();
    
          console.log("Form values:", { ...values, date: dateValue });
    
          const storedToken = localStorage.getItem("token");
    
          if (!storedToken) {
            console.error(
              "Token is not present in localStorage. Redirect or show an error message."
            );
            return;
          }
    

          const userId = user && user.id;
          console.log(userId);
    
          const response = await fetch(
            `${API_BASE_URL}/appointments/${currentAppointment.id}/update`,
            {
              method: "Put",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${storedToken}`,
              },
              body: JSON.stringify({
                ...values,
                date: dateValue, 
                created_by_user_id: userId, 
              }),
            }
          );

          const responseData = await response.json();
    
          if (response.ok) {
            form.resetFields();
            onClose();

            if (onEditSuccess) {
                onEditSuccess();
            }
            toast.success('Appointment updated successfully', {
                position: 'bottom-center'
           });
          } else if (response.status === 422) {
            // Validation error
            
            const validationErrors = responseData.errors;
    
            toast.error(`${validationErrors.join(', ')}`, {
                position: 'bottom-center'
              });
    
          } else {
            toast.error(`${responseData.error || 'Failed to save data to the database.'}`, {
                position: 'bottom-center'
              });
          }
        } catch (error) {
          toast.error(`${error.message || 'An unexpected error occurred.'}`, {
            position: 'bottom-center'
          });
        }
      };

      useEffect(() => {
        if (currentAppointment && currentAppointment.tower_id) {
          const tower = towers.find((tower) => tower.id === currentAppointment.tower_id);
          if (tower) {
            setSelectedProject(tower.project_id);
            setSelectedTower(currentAppointment.tower_id);
          }
        }
      }, [currentAppointment, towers]);

    return ( 
        <Modal
        title={`Edit appointment with client ${
            currentAppointment ? currentAppointment['clients_name'] : ''
          }`}
            open={isOpen}
            onCancel={() => {
                form.resetFields();
                onClose();
                setError(null);
              }}
              footer={[
                <Button key="back" onClick={onClose}>
                  Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                  OK
                </Button>,
              ]}
        >
            <Form
            form={form}
            onFinish={handleOk}
            initialValues={{
                // Set initial values from the currentAppointment
                date: moment(currentAppointment?.date),
                clients_name: currentAppointment?.clients_name,
                clients_contact_number: currentAppointment?.clients_contact_number,
                clients_email: currentAppointment?.clients_email,
                project_id: currentAppointment?.project_id,
                tower_id: currentAppointment?.tower_id,
                unit: currentAppointment?.unit,
                block_floornumber: currentAppointment?.block_floornumber,
                status: currentAppointment?.status,
              }}
                {...formItemLayout}
                variant="filled"
                style={{
                    maxWidth: 600,
                    marginTop: '5%',
                }}
            >
                <Form.Item
                    label="Date"
                    name="date"
                    rules={[{
                        required:true,
                        message: "Date is required."
                    }]}
                >
                    <DatePicker/>
                </Form.Item>
                <Form.Item
                    label="Client"
                    name="clients_name"
                    rules={[{
                        required:true,
                        message: "Client is required."
                    }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
          label="Client Contact Number"
          name="clients_contact_number"
          
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Client Email"
          name="clients_email"
          
        >
          <Input />
          </Form.Item>
                <Form.Item
          label="Project"
          name="project_id"
          rules={[
            {
              required: true,
              message: "Project is required.",
            },
          ]}
        >
          <Select onChange={(value) => setSelectedProject(value)}>
            {projects.map((project) => (
              <Select.Option key={project.id} value={project.id}>
                {project.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {selectedProject && (
          <Fragment>
            {towers.filter((tower) => tower.project_id === selectedProject)
              .length > 0 ? (
              <Form.Item
                label="Tower"
                name="tower_id"
                rules={[
                  {
                    required: true,
                    message: "Tower is required.",
                  },
                ]}
              >
                <Select onChange={(value) => setSelectedTower(value)}>
                  {towers
                    .filter((tower) => tower.project_id === selectedProject)
                    .map((tower) => (
                      <Select.Option key={tower.id} value={tower.id}>
                        {tower.phase_tower}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            ) : null}
          </Fragment>
        )}
                <Form.Item
          label="Block or Floor Number"
          name="block_floornumber"
          rules={[
            {
              required: true,
              message: "Block or Floor Number is required.",
            },
          ]}
        >
          <Input />
        </Form.Item>
                <Form.Item
                    label="Unit"
                    name="unit"
                    rules={[{
                        required:true,
                        message: "Unit is required."
                    }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
    label="Status"
    name="status"
    rules={[
      {
        required: true,
        message: "Status is required.",
      },
    ]}
  >
    <Select onChange={(value) => setSelectedStatus(value)}>
      {statusOptions.map((status) => (
        <Select.Option key={status} value={status}>
          {status}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
            </Form>
        </Modal>
    );
}
 
export default EditAppointmentModal;