import React, { useRef, useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import { useNavigate } from 'react-router-dom';
import { DatePicker } from "antd";
import moment from "moment";

import "../index.css";

import { Header } from "../components/common/";

const PointsPage = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [pointsData, setPointsData] = useState([]);
  const searchInput = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if token is not present, redirect to login page
    if (!localStorage.getItem('token')) {
      navigate('/login'); // Redirect to login page
    } else {
      // Token is present, continue with the component logic
      fetchAppointmentData();
    }
  }, []);

  const fetchAppointmentData = () => {
    const storedToken = localStorage.getItem("token");

      if (!storedToken) {
        console.error("Token is not present in localStorage. Redirect or show an error message.");
        return;
      }
    }

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedToken = localStorage.getItem("token");

        if (!storedToken) {
          console.error(
            "Token is not present in localStorage. Redirect or show an error message."
          );
          return;
        }

        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        };

        const response = await fetch(`${API_BASE_URL}/points`, {
          headers,
        });
        const data = await response.json();

        // Check for the error key in the response
        if (data.error) {
          console.error("Error from API:", data.error);
          return;
        }

        setPointsData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
};
const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
};

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }}>
        {dataIndex === "date_uploaded" || dataIndex === "rf_date" ? (
          <DatePicker.RangePicker
            format="YYYY-MM-DD"
            value={
              selectedKeys[0] && selectedKeys[1]
                ? [moment(selectedKeys[0]), moment(selectedKeys[1])]
                : []
            }
            onChange={(dates) =>
              {
                return setSelectedKeys(dates ? [dates[0].format("YYYY-MM-DD"), dates[1].format("YYYY-MM-DD")] : []);
              }
            }
            style={{ marginBottom: 8, display: "block" }}
          />
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
        )}
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Date Uploaded",
      dataIndex: "date_uploaded",
      key: "date_uploaded",
      width: "15%",
      ...getColumnSearchProps("date_uploaded"), // Add filter props for date column
    },
    {
      title: "RF Date",
      dataIndex: "rf_date",
      key: "rfDate",
      width: "15%",
      ...getColumnSearchProps("rf_date"), // Add filter props for date column
    },
    // {
    //   title: "Booked date",
    //   dataIndex: "booked_date",
    //   key: "bookedDate",
    //   width: "15%",
    //   filterMultiple: false,
    //   sortDirections: ["descend", "ascend"],
    // },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      width: "15%",
    },
    {
      title: "Unit No.",
      dataIndex: "unit_no",
      key: "unitNo",
      width: "15%",
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      width: "10%",
    },
    {
      title: "Selling Price",
      dataIndex: "selling_price",
      key: "price",
      width: "10%",
      render: (data) => data ? data.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",
    },
    {
      title: "NSP",
      dataIndex: "nsp",
      key: "nsp",
      width: "10%",
      responsive: ["md"],
      render: (data) => data ? data.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",

    },
    {
      title: "Points Awarded",
      dataIndex: "points_awarded",
      key: "pointsAwarded",
      width: "15%",
      render: (data) => data ? data.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",
    },
    {
      title: "Points Deducted",
      dataIndex: "points_deduction",
      key: "points_deduction",
      width: "15%",
      render: (data) => data ? data.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: "15%",
    },
  ];

  return (
    <div id="body">
      <Header pageName="My Points" />
      <div id="page">
        <Table
          rowClassName={() => "editable-row"}
          bordered
          dataSource={pointsData}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default PointsPage;
