import React from 'react';
import { Pie } from '@ant-design/plots';
import '../../PieChart.css';

const formatNumber = (number) => {
  return new Intl.NumberFormat().format(number);
};

const PieChart = ({ data }) => {
  const config = {
    data: data,
    angleField: 'total_production',
    colorField: 'name',
    radius: 0.8,
    label: {
      text: (d) => `${d.name}\n ₱${formatNumber(d.total_production)}`,
      position: 'spider',
      offset: 20,
      layout: [
        {
          type: 'adjust-layout', // Adjust label layout
          autoRotate: true, // Enable automatic label rotation
          autoEllipsis: true, // Enable automatic label ellipsis
        },
      ],
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  return (
    
    <Pie {...config} />

  );
};

export default PieChart;
