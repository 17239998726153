import React, { useState, useEffect } from 'react';
import { Input, Card, Row, Col, Modal, Button, Dropdown, Menu, Form, InputNumber, Alert, Tabs, Checkbox } from 'antd'; 
import { Header } from '../components/common';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const { TabPane } = Tabs;
const { Search } = Input;
const { TextArea } = Input;

function callback(key) {
  console.log(key);
}


const RewardsCatalogPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [isCheckout, setIsCheckout] = useState(false); 
  const [sortOrder, setSortOrder] = useState('asc');
  const [formData, setFormData] = useState({ name: '', address: '' });
  const [showCheckoutColumn, setShowCheckoutColumn] = useState(false);
  const [form] = Form.useForm(); 
  const [quantity, setQuantity] = useState(1);
  const [rewardsData, setRewardsData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if token is not present, redirect to login page
    if (!localStorage.getItem('token')) {
      navigate('/login'); // Redirect to login page
    } else {
      // Token is present, continue with the component logic
      fetchAppointmentData();
    }
  }, []);

  const fetchAppointmentData = () => {
    const storedToken = localStorage.getItem("token");

      if (!storedToken) {
        console.error("Token is not present in localStorage. Redirect or show an error message.");
        return;
      }
    }

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
        try {
            const storedToken = localStorage.getItem("token");

            if (!storedToken) {
                console.error("Token is not present in localStorage. Redirect or show an error message.");
                return;
            }

            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${storedToken}`,
            };

            const response = await fetch(`${API_BASE_URL}/rewards`, { headers });
            const data = await response.json();

            // Check for the error key in the response
            if (data.error) {
                console.error('Error from API:', data.error);
                return;
            }

            setRewardsData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
}, []);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    if (product.name === "iPad 10.2-inch (9th generation)", " ") {
      setShowCheckoutColumn(true);
    } else {
      setShowCheckoutColumn(false);
    }
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setShowCheckoutColumn(false);
  };

  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    console.log(e, 'I was closed.');
  };

  const handleOrderPlacement = () => {
    alert(`Order placed successfully!`);
    setCartItems([]);
    setTotalPoints(0);
    setIsCheckout(false); 
    setFormData({ name: '', address: '' }); 
    form.resetFields();
  };

  const handleSortChange = (order) => {
    setSortOrder(order);
  };

  const sortedItems = [...rewardsData].sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.price - b.price;
    } else {
      return b.price - a.price;
    }
  });

  const handleFormSubmit = async (values) => {
    try {
      const storedToken = localStorage.getItem("token");

      if (!storedToken) {
        console.error("Token is not present in localStorage. Redirect or show an error message.");
        return;
      }

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      };

      const response = await fetch(`${API_BASE_URL}/rewards/cart`, {
        method: 'POST',
        headers,
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error('Failed to create reward.');
      }

      alert(`Order placed successfully!`);
      
    } catch (error) {
      console.error('Error placing order:', error.message);
    }
  };

  const handleQuantityChange = (value) => {
    setQuantity(value);
    const selectedProduct = rewardsData.find((product) => product.id === form.getFieldValue('catalog_id'));
  
    if (selectedProduct) {
      const totalPoints = value * selectedProduct.points;
      setTotalPoints(totalPoints);
    }
  };

  const removeHtmlTags = (htmlString) => {
    if (!htmlString) return ''; // Return an empty string if htmlString is null or undefined
    const regex = /(<([^>]+)>)/gi;
    return htmlString.replace(regex, "");
  };



  return (
    <div id="body">
      <Header pageName="Rewards Catalog"/>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
        <Search
          placeholder="Search products"
          allowClear
          enterButton="Search"
          size="large"
          onSearch={handleSearch}
          style={{ marginRight: 10 }}
        />
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => handleSortChange('asc')}>Ascending</Menu.Item>
              <Menu.Item onClick={() => handleSortChange('desc')}>Descending</Menu.Item>
            </Menu>
          }
        >
          <Button>
            Sort by Points
          </Button>
        </Dropdown>
      </div>
      <Row gutter={[16, 16]}>
        {sortedItems
          .filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((item, index) => (
            <Col span={8} key={item.id}>
              <Card style={{ height: '90%' }}>
                <Link to="#" onClick={() => handleProductClick(item)}>
                  <div style={{ textAlign: 'center' }}>
                    <img src={item.image_url} alt={item.name} style={{ maxWidth: '50%', height: 'auto' }} />
                  </div>
                  <p>{item.name}</p>
                  <p>Points: {(item.points).toLocaleString()} pts</p>
                </Link>
              </Card>
            </Col>
          ))}
      </Row>
      {selectedProduct && (
        <Modal
          title={selectedProduct.name}
          visible={!!selectedProduct} 
          onCancel={handleCloseModal}
          width={800} 
          footer={null} // Remove the footer
        >
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <div style={{ maxWidth: 800 }}>
              <p>Points: {(selectedProduct.points).toLocaleString()} pts</p>
              <img
  src={selectedProduct.image_url}
  alt={selectedProduct.name}
  style={{ maxWidth: '60%', height: 'auto', margin: '0 auto', display: 'block' }}
/>
              <p align='center'> <br /> {removeHtmlTags(selectedProduct.description)}</p>

              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Description" key="1">
                  Once you get the item...
                  <br/>
                  <br/>
                  1. Get the baksnkajshkaj <br/>
                  2. Get the baksnkajshkaj <br/>
                  3. Get the baksnkajshkaj <br/>
                  4. Get the baksnkajshkaj <br/>
                  5. Get the baksnkajshkaj <br/>
                  6. Get the baksnkajshkaj <br/>
                </TabPane>
                <TabPane tab="Terms and Conditions" key="2">
                  <br/>
                  Welcome to our Rewards Catalog! Before you start redeeming your hard-earned points for exciting rewards, please take a moment to review the following terms and conditions:
                  <br/>
                  <br/>
                  1. To access and redeem rewards from our catalog, you must be a registered member of our rewards program and comply with all program terms and conditions.<br/>
                  2. Points earned through eligible transactions can be redeemed for rewards listed in the catalog. The number of points required for each reward item is specified in the catalog and is subject to change without prior notice.<br/>
                  3. All rewards are subject to availability. We reserve the right to substitute any reward item with another of equal or greater value without prior notice.<br/>
                  4. For physical rewards, shipping and handling fees may apply. These fees will be clearly stated at the time of redemption and must be paid using points or other accepted payment methods, if applicable.<br/>
                  5. We aim to deliver physical rewards within a reasonable timeframe; however, delivery times may vary depending on factors such as location and product availability. Digital rewards will be delivered electronically to the email address associated with your account.<br/>
                  6. All reward redemptions are final and non-refundable. However, if you receive a damaged or defective item, please contact customer support within [X] days of receipt for assistance.<br/>
                </TabPane>
              </Tabs>

              {showCheckoutColumn && ( 
                <div>
                  <br/>
                  <p>Personal Information</p>
                  <Form
                    form={form} 
                    onFinish={handleFormSubmit}
                    initialValues={{ catalog_id: selectedProduct.id, quantity: 1 }}
                    onValuesChange={(changedValues) => setFormData({ ...formData, ...changedValues })}
                  >
                    <Form.Item label="Catalog ID" name="catalog_id" hidden>
                      <Input />
                    </Form.Item>

                    <Form.Item label="Address" name="address" rules={[{ required: true, message: 'Please enter your address' }]}>
                      <TextArea rows={2} />
                    </Form.Item>
                    <Form.Item label="Phone Number" name="contact_number" rules={[{ required: true, message: 'Please enter your phone number' }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item label="Additional Information" name="additional_information">
                      <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item label="Quantity" name="quantity" rules={[{ required: true, message: 'Please enter quantity' }]}>
    <InputNumber min={1} onChange={handleQuantityChange} />
  </Form.Item>
  <Form.Item label="Total Points" name="total_points">
    <InputNumber value={totalPoints} disabled />
  </Form.Item>
  <Form.Item>
  <Form.Item>
  <Checkbox
    name="terms_condition"
    defaultChecked={false} // Set defaultChecked to false
    onChange={(e) => setFormData({ ...formData, terms_condition: e.target.checked })} // Update formData based on checkbox state
    rules={[{ required: true, message: 'Please agree to the Terms and Conditions' }]} // Add validation rule for the checkbox
  >
    I agree to the Terms and Conditions
  </Checkbox>
</Form.Item>
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">Checkout</Button>
                    </Form.Item>
                  </Form>
                  <Alert
                    message="Please make sure you (or an authorized representative) are at the delivery address to receive the item. Please also reminded with the protocols."
                    type="info"
                    closable
                    onClose={onClose}
                  />
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default RewardsCatalogPage;
