import React, { useEffect, useState } from 'react';
import { Button, Table, ConfigProvider} from 'antd';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Header } from "../components/common";
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx'; 
import axios from 'axios';

import "../index.css";

const NewFormsPage = () => {
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const API_BASE_URL_API = process.env.REACT_APP_API_BASE_URL_API;

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/downloadable-forms`);
        setAppointments(response.data.downloadableForms);
      } catch (error) {
        console.error('Error fetching forms:', error);
      }
    };

    fetchForms();
  }, []);

  const handleDownload = async (id, fileName, filePath) => {
    try {
      const storedToken = localStorage.getItem("token");
  
      if (!storedToken) {
        console.error("Token is not present in localStorage. Redirect or show an error message.");
        return;
      }
  
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      };
  
      const response = await axios.get(`${API_BASE_URL}/downloadable-forms/${id}/download`, {
        headers,
        responseType: 'blob', // Ensure response is treated as a blob
      });

      // Create a blob URL for the file
      const blobUrl = URL.createObjectURL(new Blob([response.data]));
      
      // Create a temporary anchor element to trigger the file download
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Clean up the blob URL after download
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading form:', error);
    }
  };

  const exportToExcel = (record) => {
    const exportData = [{
      Description: record.description,
      FileName: record.file_name
    }];

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Forms');
    XLSX.writeFile(workbook, 'forms.xlsx');
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      width: '15%',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '15%',
    },
    
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {/* Use a link to trigger the download */}
          <a href={`${API_BASE_URL_API}/storage/downloads/${record.forms}`} download target="_blank" rel="noopener noreferrer">
            Download
          </a>
        </div>
      ),
    },
  ];

  return (
    <div id='body'>
      <Header pageName="Forms"/>
      <div id="page">
        <Table
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={appointments}
          columns={columns}
          className='w-100 h-100'
        />
        <ToastContainer/>
      </div>
    </div>
  );
};

export default NewFormsPage;
