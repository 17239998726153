import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Table,
  Avatar,
  Button,
  Image,
  Carousel,
  Card,
  Col,
  Row,
  Statistic,
  DatePicker,
  List,
  message,
  Divider,
  Modal,
} from "antd";
import VirtualList from "rc-virtual-list";
import { LineChart } from "@mui/x-charts/LineChart";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { Bar, Column } from "@ant-design/plots";
import { MoreOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../services/useAuth";

import "../index.css";
import "../PieChart.css";
import "../styles/Dashboard.css";

import { BarGraph, Header, PieChart } from "../components/common/";

const { RangePicker } = DatePicker;

const { Option } = Select;

const monthMapping = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

const DashboardPage = () => {
  const [loading, setLoading] = useState(true);

  const [totalCommission, setTotalCommission] = useState(0);
  const [appointmentTrendData, setAppointmentTrendData] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [sales, setSales] = useState([]);
  const [soldUnits, setSoldUnits] = useState(0);
  const [soldProd, setSoldProd] = useState(0);
  const [appointmentsWeekly, setAppointmentsWeekly] = useState(0);
  const [appointmentsYearly, setAppointmentsYearly] = useState(0);
  const [points, setPoints] = useState(0);
  const [announcements, setAnnouncements] = useState([]);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [leaderboardDataRole, setLeaderboardDataRole] = useState([]);
  const [sellerCardsData, setSellerCardsData] = useState([]);
  const [selectedRole, setSelectedRole] = useState("Property Consultant");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [loggedInUserId, setLoggedInUserId] = useState("");
  const [selectedFilterId, setSelectedFilterId] = useState("all");
  const [selectedManagerData, setSelectedManagerData] = useState(null);
  const [selectedManager, setSelectedManager] = useState(null);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [appointmentsDataSm, setAppointmentsDataSm] = useState([]);
  const [appointmentsDataSd, setAppointmentsDataSd] = useState([]);
  const [selectedManagerForBarChart, setSelectedManagerForBarChart] =
    useState(null);
  const [selectedManagerForPieChart, setSelectedManagerForPieChart] =
    useState(null);
  const [
    selectedManagerForAppointmentsTable,
    setSelectedManagerForAppointmentsTable,
  ] = useState(null);

  const allSellers = sellerCardsData.flatMap((manager) => manager.sellers);

  const formatAppointmentTrendData = (data) => {
    return data
      .map((item) => ({
        ...item,
        monthValue: monthMapping[item.month],
        label: `${item.month} ${item.year}`,
      }))
      .sort((a, b) => {
        if (a.year === b.year) {
          return a.monthValue - b.monthValue;
        }
        return a.year - b.year;
      });
  };

  const formattedAppointmentTrendData =
    formatAppointmentTrendData(appointmentTrendData);

  const formatYAxisLabel = (value) => {
    const formattedValue = value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `${formattedValue}`;
  };

  const formatTooltipLabel = (value) => {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  useEffect(() => {
    if (user) {
      // setUserRole(user.roles[0].name); // Assuming roles is an array and you're interested in the first role
      setLoggedInUserId(user.id); // Assuming user id is available in the user object
      // You can similarly set other user-related states here
    }
  }, [loading, user]);

  const fetchAppointmentData = () => {
    const storedToken = localStorage.getItem("token");

    if (!storedToken) {
      console.error(
        "Token is not present in localStorage. Redirect or show an error message."
      );
      return;
    }
  };

  const [defaultFromDate, setDefaultFromDate] = useState(
    moment().startOf("year")
  );
  const [defaultToDate, setDefaultToDate] = useState(moment());
  const [barData, setBarData] = useState([]);
  const [barDataAppt, setBarDataAppt] = useState([]);
  const [graphType, setGraphType] = useState("daily");
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [isSalesModalOpen, setIsSalesModalOpen] = useState(false);
  const [yAxisTickRotation, setYAxisTickRotation] = useState(-45);
  const [yAxisTickFormatter, setYAxisTickFormatter] = useState((value) =>
    value ? value.toLocaleString() : ""
  );
  const [weeklyAppointmentCountData, setWeeklyAppointmentCountData] = useState(
    []
  );
  const [defaultManager, setDefaultManager] = useState(null);

  useEffect(() => {
    if (userRole === "sales director" && sellerCardsData.length > 0) {
      setDefaultManager(sellerCardsData[0]);
    }
  }, [userRole, sellerCardsData]);

  console.log("defaultManager", defaultManager);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const statusOptions = [
    "Property Consultant",
    "Sales Manager",
    "Sales Director",
    "VP",
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => text.toUpperCase(),
    },
    {
      title: "Production",
      dataIndex: "production",
      key: "production",
      render: (data) => data.toLocaleString(),
    },
  ];

  const showSalesModal = () => {
    setIsSalesModalOpen(true);
  };

  const handleSalesModalCancel = () => {
    setIsSalesModalOpen(false);
  };

  useEffect(() => {
    fetchData(); // Fetch data when fromDate, toDate, graphType, selectedFilterId, userRole, or loggedInUserId changes
  }, [fromDate, toDate, graphType, selectedFilterId, userRole, loggedInUserId]);

  useEffect(() => {
    const handleResize = () => {
      const chartWidth = 600; // Replace with the actual chart width
      const labelLength = Math.max(
        ...appointmentTrendData
          .filter((item) => item && item.totalSum !== undefined)
          .map((item) => item.totalSum.toString().length)
      );

      // Adjust label rotation based on chart width and label length
      const maxRotation = -90;
      const minRotation = 0;
      const rotationRange = maxRotation - minRotation;
      const rotationFactor = Math.max(
        0,
        Math.min(1, (chartWidth - labelLength * 8) / (chartWidth / 2))
      );
      const rotation = minRotation + rotationRange * rotationFactor;
      setYAxisTickRotation(rotation);

      // Adjust label formatting based on label length
      const formatter =
        labelLength > 6
          ? (value) => (value ? `${value / 1000}k` : "")
          : (value) => (value ? value.toLocaleString() : "");
      setYAxisTickFormatter(formatter);
    };

    handleResize(); // Call the resize handler initially
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [appointmentTrendData]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const storedToken = localStorage.getItem("token");
      if (!storedToken) {
        console.error(
          "Token is not present in localStorage. Redirect or show an error message."
        );
        return;
      }

      // Set default values for fromDate and toDate if they are null or undefined
      const defaultFromDate = moment().startOf("year");
      const defaultToDate = moment();

      let params = {
        role: selectedRole,
        from_date: fromDate || defaultFromDate.format("YYYY-MM-DD"),
        to_date: toDate || defaultToDate.format("YYYY-MM-DD"),
        selected_seller_id: selectedFilterId,
      };

      const response = await axios.get(`${API_BASE_URL}/dashboard`, {
        params,
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      // Destructure user data from the response
      const {
        userRole,
        totalCommission,
        leaderboardData,
        leaderboardDataRole,
        subordinatesData,
        appointmentsData,
        salesData,
        totalSoldUnits,
        totalSoldProd,
        weeklyAppointmentsCount,
        yearlyAppointmentsCount,
        totalPoints,
        unitsSoldData,
        unitsSoldDataAppt,
        announcements,
        appointmentTrendData,
      } = response.data;

      // Update states with the fetched data
      setUserRole(userRole);
      setTotalCommission(totalCommission);
      setLeaderboardData(leaderboardData.slice(0, 20)); // Assuming leaderboardData is an array
      setLeaderboardDataRole(leaderboardDataRole.slice(0, 20));
      setSellerCardsData(subordinatesData); // Assuming subordinatesData is an array
      setAppointments(appointmentsData); // Assuming appointmentsData is an array
      setSales(salesData); // Assuming salesData is an array
      setSoldUnits(totalSoldUnits);
      setSoldProd(totalSoldProd);
      setAppointmentsWeekly(weeklyAppointmentsCount);
      setAppointmentsYearly(yearlyAppointmentsCount);
      setAppointmentTrendData(appointmentTrendData);
      setPoints(totalPoints);
      setBarData(unitsSoldData); // Assuming unitsSoldData is an array
      setWeeklyAppointmentCountData(unitsSoldDataAppt); // Assuming unitsSoldDataAppt is an array
      setAnnouncements(announcements); // Assuming announcements is an array
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Error fetching dashboard data. Please try again.");
    }
    setLoading(false);
  };

  console.log('sellerCardsData:',sellerCardsData)
  const barGraphData = sellerCardsData.map((subordinate) => ({
    name: subordinate.name,
    salesCount: subordinate.sales_count,
  }));

  const pieChartData = sellerCardsData.map((subordinate) => ({
    name: subordinate.name,
    total_production: parseFloat(subordinate.total_production),
  }));

  useEffect(() => {
    const fetchAppointmentsData = async () => {
      try {
        const storedToken = localStorage.getItem("token");
        if (!storedToken) {
          console.error(
            "Token is not present in localStorage. Redirect or show an error message."
          );
          return;
        }

        if (selectedManagerData) {
          const sellersData = selectedManagerData.sellers.map((seller) => ({
            id: seller.id,
            name: seller.name,
            appointments: seller.appointments,
          }));
          setAppointmentsDataSm(sellersData);
        } else {
          setAppointmentsDataSm([]);
        }
      } catch (error) {
        console.error("Error fetching appointments data:", error);
        message.error("Error fetching appointments data. Please try again.");
      }
    };

    fetchAppointmentsData();
  }, [selectedManagerData]);

  const handleGraphTypeChange = (value) => {
    const selectedManagerData = sellerCardsData.find(
      (manager) => manager.name === value
    );
    setSelectedManagerData(selectedManagerData);
    setSelectedManager(selectedManagerData);
  };

  const handleDateRangeChange = (dates) => {
    if (dates !== null) {
      setFromDate(dates[0]?.format("YYYY-MM-DD") || null);
      setToDate(dates[1]?.format("YYYY-MM-DD") || null);
      setSelectedDateRange(dates);
    } else {
      setFromDate(null);
      setToDate(null);
      setSelectedDateRange(undefined);
    }
  };

  const handleFilterChange = (value) => {
    setSelectedFilterId(value); // Update selectedFilterId state on filter change
  };

  const handleBarChartManagerChange = (value) => {
    if (value === "all") {
      setSelectedManagerForBarChart(null); // Set selectedManagerForBarChart to null for "All Sellers"
    } else {
      setSelectedManagerForBarChart(
        sellerCardsData.find((manager) => manager.name === value)
      );
    }
  };

  const handlePieChartManagerChange = (value) => {
    if (value === "all") {
      setSelectedManagerForPieChart(null); // Set selectedManagerForPieChart to null for "All Sellers"
    } else {
      setSelectedManagerForPieChart(
        sellerCardsData.find((manager) => manager.name === value)
      );
    }
  };

  const handleAppointmentsTableManagerChange = (value) => {
    if (value === "all") {
      setSelectedManagerForAppointmentsTable(null); // Set selectedManagerForAppointmentsTable to null for "All Sellers"
    } else {
      setSelectedManagerForAppointmentsTable(
        sellerCardsData.find((manager) => manager.name === value)
      );
    }
  };

  const appointmentsTableColumnsPc = [
    {
      title: "Week Range",
      dataIndex: "weekRange",
      key: "weekRange",
    },
    {
      title: "Appointments",
      dataIndex: "totalAppointments",
      key: "totalAppointments",
    },
  ];

  const appointmentsTableDataPc = weeklyAppointmentCountData.map((item) => ({
    key: `${item.year}-${item.week}`,
    weekRange: item.weekRange,
    totalAppointments: item.totalAppointments,
  }));

  const weeks = ["Week 1", "Week 2", "Week 3", "Week 4"];

  const appointmentsTableColumnsSm = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    ...(sellerCardsData.length > 0
    ?sellerCardsData.flatMap((subordinate) =>
      subordinate.appointments
        ? subordinate.appointments.map((appointment, index) => ({
            title: `${appointment.week_start} - ${appointment.week_end}`,
            dataIndex: `week${index + 1}`,
            key: `week${index + 1}`,
          }))
        : []
    )
    : []),
  ];


  const appointmentsTableDataSm = sellerCardsData.length > 0
  ? sellerCardsData
      .filter((subordinate) => subordinate && subordinate.id && subordinate.appointments)
      .map((subordinate) => ({
        key: subordinate.id,
        name: subordinate.name,
        ...(subordinate.appointments
          ? subordinate.appointments.reduce(
              (acc, appointment, index) => ({
                ...acc,
                [`week${index + 1}`]: appointment.total_appointments,
              }),
              {}
            )
          : {}),
      }))
  : [];


  const appointmentsTableColumnsSd = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    ...(sellerCardsData.length > 0
      ? sellerCardsData.flatMap((manager) =>
          manager.appointments
            ? manager.appointments.map((appointment, index) => ({
                title: `${appointment.week_start} - ${appointment.week_end}`,
                dataIndex: `week${index + 1}`,
                key: `week${index + 1}`,
              }))
            : []
        )
      : []),
  ];

  const appointmentsTableDataSd =
    sellerCardsData.length > 0
      ? sellerCardsData.map((manager) => ({
          key: manager.id,
          name: manager.name,
          ...(manager.appointments
            ? manager.appointments.reduce(
                (acc, appointment, index) => ({
                  ...acc,
                  [`week${index + 1}`]: appointment.total_appointments,
                }),
                {}
              )
            : {}),
        }))
      : [];

    const appointmentsTableColumnsSdSm = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      ...allSellers.flatMap((seller, sellerIndex) =>
        seller && seller.appointments
          ? seller.appointments.map((appointment, appointmentIndex) => ({
              title: `${appointment.week_start} - ${appointment.week_end}`,
              dataIndex: `week${sellerIndex + 1}_${appointmentIndex + 1}`,
              key: `week${sellerIndex + 1}_${appointmentIndex + 1}`,
            }))
          : []
      ),
      
    ];
    
  
    const appointmentsTableDataSdSm = allSellers
    .filter((seller) => seller && seller.id) // Filter out undefined or sellers without id
    .map((seller) => ({
      key: seller.id,
      name: seller.name,
      ...seller.appointments
        ? seller.appointments.reduce(
            (acc, appointment, index) => ({
              ...acc,
              [`week${index + 1}_${index + 1}`]: appointment.total_appointments,
            }),
            {}
          )
        : {},
    }));
  
    




  // const pieChartData = [
  //   { name: "Alice", value: 300000 },
  //   { name: "Bob", value: 250000 },
  //   { name: "Charlie", value: 200000 },
  //   { name: "David", value: 150000 },
  //   { name: "Eva", value: 100000 },
  // ];

  // const formattedAppointmentTrendData2 = [
  //   { label: "January", totalSum: 1_500_000 },
  //   { label: "February", totalSum: 2_000_000 },
  //   { label: "March", totalSum: 1_800_000 },
  //   { label: "April", totalSum: 2_200_000 },
  //   { label: "May", totalSum: 2_100_000 },
  //   { label: "June", totalSum: 2_500_000 },
  //   { label: "July", totalSum: 2_300_000 },
  //   { label: "August", totalSum: 2_400_000 },
  //   { label: "September", totalSum: 2_600_000 },
  //   { label: "October", totalSum: 2_800_000 },
  //   { label: "November", totalSum: 3_000_000 },
  //   { label: "December", totalSum: 3_200_000 },
  // ];

  return (
    <div id="body">
      <Header pageName="Dashboard" />

      <div id="page">
        <div className="image-slideshow">
          <Carousel
            autoplay
            effect="fade"
            infinite
            dots={true}
            pauseOnDotsHover={true}
            style={{ width: "600px" }}
            swipeToSlide={true}
            draggable
          >
            {announcements.map((announcement) => (
              <div key={announcement.id}>
                <Image
                  width="100%"
                  height="100%"
                  src={announcement.image_url}
                  alt={`Announcement`}
                  className="image"
                />
              </div>
            ))}
          </Carousel>
        </div>
        {
          /*(userRole === "sales manager" ||
          userRole === "property consultant" ||
          userRole === "sales director" || userRole === "AVP")*/ true && (
            <div>
              <p
                style={{
                  fontSize: "20px",
                  color: "#A98F4E",
                  margin: 5,
                  fontWeight: 500,
                }}
              >
                Select Time Range:
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "1%",
                }}
              >
                <RangePicker
                  value={selectedDateRange}
                  onChange={handleDateRangeChange}
                />
                {
                  /*(userRole === "sales manager" || userRole === "sales director" || userRole === "AVP" ) */ true && (
                    <div>
                      {fromDate &&
                        toDate && ( // Conditionally render Select component
                          <Form.Item>
                            <Select
                              defaultValue="all"
                              onChange={handleFilterChange}
                            >
                              <Option value="all">All</Option>
                              {sellerCardsData.map((seller) => (
                                <Option key={seller.id} value={seller.id}>
                                  {seller.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                    </div>
                  )
                }
              </div>

              <Row wrap="true" gutter={16} style={{ marginBottom: "2%" }}>
                <Col flex="auto" span={12}>
                  <Link to={"/sale"}>
                    <Card bordered={false} hoverable={true}>
                      <div className="sold-units-value-div">
                        <Statistic
                          title="Sold Units"
                          value={soldUnits} //TODO: ternary operator for if property consultant
                          precision={0}
                          valueStyle={{
                            color: "#A98F4E",
                          }}
                        />
                        <Statistic
                          title="Value"
                          value={soldProd} //TODO: ternary operator for if property consultant
                          precision={0}
                          valueStyle={{
                            color: "#A98F4E",
                          }}
                        />
                      </div>
                    </Card>
                  </Link>
                </Col>
                <Col
                  flex="auto"
                  xs={{ flex: "100%" }}
                  sm={{ flex: "50%" }}
                  md={{ flex: "40%" }}
                  lg={{ flex: "20%" }}
                  xl={{ flex: "10%" }}
                >
                  <Link to={"/appointment"}>
                    <Card bordered={false} hoverable={true}>
                      <Statistic
                        title="Appointments"
                        value={appointmentsWeekly}
                        precision={0}
                        valueStyle={{
                          color: "#A98F4E",
                        }}
                      />
                    </Card>
                  </Link>
                </Col>
                {/* <Col
                  flex="auto"
                  xs={{ flex: "100%" }}
                  sm={{ flex: "50%" }}
                  md={{ flex: "40%" }}
                  lg={{ flex: "20%" }}
                  xl={{ flex: "10%" }}
                >
                  <Link to={"/points"}>
                    <Card bordered={false} hoverable={true}>
                      <Statistic
                        title="Points"
                        value={points}
                        precision={2}
                        valueStyle={{
                          color: "#A98F4E",
                        }}
                      />
                    </Card>
                  </Link>
                </Col> */}
                <Col
                  flex="auto"
                  xs={{ flex: "100%" }}
                  sm={{ flex: "50%" }}
                  md={{ flex: "40%" }}
                  lg={{ flex: "20%" }}
                  xl={{ flex: "10%" }}
                >
                  <Link to={"/commisions"}>
                    <Card bordered={false} hoverable={true}>
                      <Statistic
                        title="Commission"
                        value={totalCommission}
                        precision={2}
                        valueStyle={{
                          color: "#A98F4E",
                        }}
                      />
                    </Card>
                  </Link>
                </Col>
                <Col
                  flex="auto"
                  xs={{ flex: "100%" }}
                  sm={{ flex: "50%" }}
                  md={{ flex: "40%" }}
                  lg={{ flex: "20%" }}
                  xl={{ flex: "10%" }}
                >
                  <Link to={"/Leaderboard"}>
  <Card bordered={false} hoverable={true}>
    <Statistic
      title="Leaderboard"
      value={
        leaderboardDataRole.find(
          (item) => item.rank !== null
        )?.rank || "N/A"
      }
      precision={0}
      valueStyle={{
        color: "#A98F4E",
      }}
    />
  </Card>
</Link>

                </Col>
              </Row>

              {/* if user is seller manager */}
              {(userRole === "sales manager" ||
                userRole === "sales director" ||
                userRole === "VP") && (
                <div>
                  {/* <h2>Your Sellers</h2> */}
                  {/* <div id="seller-cards">
                  <Card
                    title="Units Sold of Sellers"
                    bordered={false}
                    hoverable={false}
                    style={{
                      width: "100%",
                    }}
                  >
                    <div id="seller-cards">
                      <PieChart />
                    </div>
                  </Card>
                </div> */}
                  <div id="seller-cards">
                    {/* Updated code to display subordinates */}

                    {sellerCardsData.map((sellerCard) => (
                      <Card
                        title={`Seller: ${sellerCard.name} `}
                        bordered={false}
                        style={{
                          width: 300,
                        }}
                        // extra={
                        //   <Button
                        //     type="text"
                        //     shape="round"
                        //     icon={<MoreOutlined />}
                        //     onClick={showSalesModal}
                        //   ></Button>
                        // }
                      >
                        <Statistic
                          title="Sold Units"
                          value={sellerCard.sales_count}
                          valueStyle={{ color: "#A98F4E" }}
                        />
                        <Statistic
                          title="Appointments Booked"
                          value={sellerCard.appointments_count}
                          valueStyle={{ color: "#A98F4E" }}
                        />
                      </Card>
                    ))}
                  </div>
                </div>
              )}

              {/* {(userRole === "sales director" || userRole === "AVP") && (
                <div>
                  { <Card
                  title="Units Sold per Manager"
                  bordered={false}
                  hoverable={true}
                >
                  <BarGraph />
                </Card> }
                  <Divider />

                </div>
              )} */}

              <Row gutter={16}>
                <Col span={24}>
                  {userRole === "property consultant" && (
                    <>
                      <Card
                        title="Total Sales Based on Number of Units"
                        bordered={false}
                      >
                        <BarGraph
                          data={barData}
                          isSeller={true}
                          graphType={graphType}
                          selectedDateRange={selectedDateRange}
                          fromDate={fromDate}
                          toDate={toDate}
                        />
                      </Card>
                    </>
                  )}
                  {userRole === "sales manager" && (
                    <>
                      <Card
                        title="Property Consultant Total Sales Based on Number of Units"
                        bordered={false}
                      >
                        <BarGraph
                          data={sellerCardsData}
                          isSeller={false}
                          selectedDateRange={selectedDateRange}
                          fromDate={fromDate}
                          toDate={toDate}
                        />
                        </Card>
                      </>
                    )}
                    {(userRole === "sales director" || userRole === "VP") && (
                      <>
                      <Card title="Sales Manager Total Sales Based on Number of Units" bordered={false}>
              
              <BarGraph
                data={sellerCardsData}
                isSeller={false}
                selectedDateRange={selectedDateRange}
                fromDate={fromDate}
                toDate={toDate}
              />
            </Card>
            <Card title="Property Consultant Total Sales Based on Number of Units" bordered={false}>
              <Divider orientation="left">
                Sellers of
                <span style={{ marginLeft: "20px" }}>
                <Select
  defaultValue="All Sellers"
  onChange={handleBarChartManagerChange}
>
  <Option key="all" value="all">
    All Sellers
  </Option>
  {sellerCardsData.map((manager) => (
    <Option key={manager.name} value={manager.name}>
      {manager.name}
    </Option>
  ))}
</Select>
                </span>
              </Divider>
              <BarGraph
  data={selectedManagerForBarChart ? selectedManagerForBarChart.sellers : allSellers}
  isSeller={false}
  selectedManager={selectedManagerForBarChart || null}
  selectedDateRange={selectedDateRange}
  fromDate={fromDate}
  toDate={toDate}
/>
            </Card>
                      </>
                    )}
               
                </Col>
                <Col span={24}>
                  {userRole === "property consultant" && (
                    <Card
                      bordered={false}
                      title="Total Sales Based on Selling Price"
                      style={{ overflowX: 'auto' }}
                    >
                       <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <LineChart
                          xAxis={[
                            {
                              scaleType: "point",
                              data: formattedAppointmentTrendData.map(
                                (item) => item.label
                              ),
                            },
                          ]}
                          yAxis={[
                            {
                              tickFormatter: formatYAxisLabel,
                            },
                          ]}
                          series={[
                            {
                              data: formattedAppointmentTrendData.map(
                                (item) => item?.totalSum
                              ),
                            },
                          ]}
                          tooltip={{
                            labelFormatter: formatTooltipLabel,
                          }}
                          width={900}
                          height={300}
                        />
                      </div>
                    </Card>
                  )}
                  {userRole === "sales manager" && (
                    <>
                      <Card
                        bordered={false}
                        title="Property Consultant Total Sales Based on Selling Price"
                      >
                        <div style={{ maxWidth: "100%", overflowX: "auto" }}>
                          <Divider orientation="left">Sellers</Divider>
                          <div className="pie-chart-container">
                            <PieChart data={pieChartData} />
                          </div>
                          </div>
                  </Card>
                        </>
                      )}
                      {(userRole === "sales director" || userRole === "VP") && (
                        <>
                        <Card bordered={false} title="Sales Manager Total Sales Based on Selling Price">
                        <div style={{ maxWidth: "100%", overflowX: "auto" }}>
                          <PieChart data={pieChartData} />
                        </div>
                      </Card>
                      <Card
                        bordered={false}
                        title="Property Consultant Total Sales Based on Selling Price"
                      >
                        <div style={{ maxWidth: "100%", overflowX: "auto" }}>
                          <Divider orientation="left">
                            Sellers of
                            <span style={{ marginLeft: "20px" }}>
                              <Select
                                defaultValue="All Sellers"
                                onChange={handlePieChartManagerChange}
                              >
                                <Option key="all" value="all">
                                  All Sellers
                                </Option>
                                {sellerCardsData.map((unitsSoldData) => (
                                  <Option
                                    key={unitsSoldData.name}
                                    value={unitsSoldData.name}
                                  >
                                    {sellerCardsData.name}
                                  </Option>
                                ))}
                              </Select>
                            </span>
                          </Divider>
                          <PieChart
                            data={
                              selectedManagerForPieChart
                                ? selectedManagerForPieChart.sellers.map(
                                    (seller) => ({
                                      name: seller.name,
                                      total_production: parseFloat(
                                        seller.total_production
                                      ),
                                    })
                                  )
                                : allSellers.map((seller) => ({
                                    name: seller.name,
                                    total_production: parseFloat(
                                      seller.total_production
                                    ),
                                  }))
                            }
                          />
                        </div>
                      </Card>
                    </>
                  )}
                </Col>
              </Row>

              <Row gutter={16}>
                {/* <BarGraph data={barDataAppt} graphType={graphType}
                    selectedDateRange={selectedDateRange}
                    fromDate={fromDate}
                    toDate={toDate} /> */}
                {userRole === "property consultant" && (
                  <Col span={12}>
                    <Card bordered={false} title="Appointments">
                      <div className="card-body">
                        <Table
                          columns={appointmentsTableColumnsPc}
                          dataSource={appointmentsTableDataPc}
                        />
                        </div>
                        </Card>
                        </Col>
                      )}
                      {userRole === "sales manager" && (
                        <>
                        {appointmentsTableColumnsSm.length <= 3 ? (
                          <Col span={12}>
                          <Card bordered={false} title="Appointments">
                            <div className="card-body">
                          <Table
                            columns={appointmentsTableColumnsSm}
                            dataSource={appointmentsTableDataSm}
                          />
                          </div>
                        </Card>
                        </Col>
                        ) : (
                          <Col span={24}>
                          <Card bordered={false} title="Appointments">
                            <div className="card-body">
                            <Table
                              columns={appointmentsTableColumnsSm}
                              dataSource={appointmentsTableDataSm}
                            />
                          </div>
                        </Card>
                        </Col>

                        )}
                      </>
                      )}
                      {(userRole === "sales director" || userRole === "VP") && (
                        <>
                        {appointmentsTableColumnsSd.length <= 3 ? (
                <Col span={12}>
                  <Card bordered={false} title="Appointments">
                    <div className="card-body">
                      <Divider orientation="left">Managers</Divider>
                      <Table
                        columns={appointmentsTableColumnsSd}
                        dataSource={appointmentsTableDataSd}
                      />
                    </div>
                  </Card>
                </Col>
              ) : (
                <Col span={24}>
                  <Card bordered={false} title="Appointments">
                    <div className="card-body">
                      <Divider orientation="left">Managers</Divider>
                      <Table
                        columns={appointmentsTableColumnsSd}
                        dataSource={appointmentsTableDataSd}
                      />
                    </div>
                  </Card>
                </Col>
              )}
              {appointmentsTableColumnsSdSm.length <= 3 ? (
                <Col span={12}>
                  <Card bordered={false} title="Appointments">
                    <div className="card-body">
                      <Divider orientation="left">
                        Sellers of
                        <span style={{ marginLeft: "20px" }}>
                        <Select
                               defaultValue="All Sellers"
                               onChange={handleAppointmentsTableManagerChange}
                              >
                                <Option key="all" value="all">
    All Sellers
  </Option>
                            {sellerCardsData.map((manager) => (
                              <Option key={manager.name} value={manager.name}>
                                {manager.name}
                              </Option>
                            ))}
                          </Select>
                        </span>
                      </Divider>
                      <Table
  columns={[
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    ...(selectedManagerForAppointmentsTable
      ? selectedManagerForAppointmentsTable.sellers[0]?.appointments.map((appointment, index) => ({
          title: `${appointment.week_start} - ${appointment.week_end}`,
          dataIndex: `week${index + 1}`,
          key: `week${index + 1}`,
        }))
      : allSellers.flatMap((seller, sellerIndex) =>
          seller.appointments
            ? seller.appointments.flatMap((appointment, appointmentIndex) => ({
                title: `${appointment.week_start} - ${appointment.week_end}`,
                dataIndex: `week${sellerIndex + 1}_${appointmentIndex + 1}`,
                key: `week${sellerIndex + 1}_${appointmentIndex + 1}`,
              }))
            : []
        )),
  ]}
  dataSource={
    selectedManagerForAppointmentsTable
      ? selectedManagerForAppointmentsTable.sellers.map((seller) => ({
          key: seller.id,
          name: seller.name,
          ...seller.appointments
            ? seller.appointments.reduce(
                (acc, appointment, index) => ({
                  ...acc,
                  [`week${index + 1}`]: appointment.total_appointments,
                }),
                {}
              )
            : {},
        }))
      : allSellers.map((seller, sellerIndex) => ({
          key: seller.id,
          name: seller.name,
          ...seller.appointments
            ? seller.appointments.reduce(
                (acc, appointment, appointmentIndex) => ({
                  ...acc,
                  [`week${sellerIndex + 1}_${appointmentIndex + 1}`]: appointment.total_appointments,
                }),
                {}
              )
            : {},
        }))
  }
/>
                    </div>
                  </Card>
                </Col>
              ) : (
                <Col span={24}>
                  <Card bordered={false} title="Appointments">
                    <div className="card-body">
                      <Divider orientation="left">
                        Sellers of
                        <span style={{ marginLeft: "20px" }}>
                        <Select
                               defaultValue="All Sellers"
                               onChange={handleAppointmentsTableManagerChange}
                              >
                                <Option key="all" value="all">
    All Sellers
  </Option>
                            {sellerCardsData.map((manager) => (
                              <Option key={manager.name} value={manager.name}>
                                {manager.name}
                              </Option>
                            ))}
                          </Select>
                        </span>
                      </Divider>
                      <Table
  columns={[
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    ...(selectedManagerForAppointmentsTable
      ? selectedManagerForAppointmentsTable.sellers[0]?.appointments.map((appointment, index) => ({
          title: `${appointment.week_start} - ${appointment.week_end}`,
          dataIndex: `week${index + 1}`,
          key: `week${index + 1}`,
        }))
      : allSellers.flatMap((seller, sellerIndex) =>
          seller.appointments
            ? seller.appointments.flatMap((appointment, appointmentIndex) => ({
                title: `${appointment.week_start} - ${appointment.week_end}`,
                dataIndex: `week${sellerIndex + 1}_${appointmentIndex + 1}`,
                key: `week${sellerIndex + 1}_${appointmentIndex + 1}`,
              }))
            : []
        )),
  ]}
  dataSource={
    selectedManagerForAppointmentsTable
      ? selectedManagerForAppointmentsTable.sellers.map((seller) => ({
          key: seller.id,
          name: seller.name,
          ...seller.appointments
            ? seller.appointments.reduce(
                (acc, appointment, index) => ({
                  ...acc,
                  [`week${index + 1}`]: appointment.total_appointments,
                }),
                {}
              )
            : {},
        }))
      : allSellers.map((seller, sellerIndex) => ({
          key: seller.id,
          name: seller.name,
          ...seller.appointments
            ? seller.appointments.reduce(
                (acc, appointment, appointmentIndex) => ({
                  ...acc,
                  [`week${sellerIndex + 1}_${appointmentIndex + 1}`]: appointment.total_appointments,
                }),
                {}
              )
            : {},
        }))
  }
/>
                    </div>
                  </Card>
                </Col>
              )}
                        </>
                      )}

                <Col span={12}>
                  <Card bordered={false} title="Leaderboard">
                    <div>
                      <Form onFinish={fetchData}>
                        <Form.Item name="role" label="Select Role">
                          <Select
                            onChange={(value) => setSelectedRole(value)}
                            defaultValue="Property Consultant"
                          >
                            {statusOptions.map((status) => (
                              <Select.Option key={status} value={status}>
                                {status}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                          >
                            Show Leaderboard
                          </Button>
                        </Form.Item>
                      </Form>
                      <Table
                        columns={columns}
                        dataSource={leaderboardData}
        pagination={{
                          pageSize: 20,
                        }}
                        loading={loading}
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          )
        }
      </div>
      <Modal
        title="Sales and Appointments of Seller Name"
        open={isSalesModalOpen}
        onCancel={handleSalesModalCancel}
        width="70%"
        height="auto"
        styles={{
          body: { overflowY: "auto", maxHeight: "calc(100vh - 200px)" },
        }}
        footer=""
      >
        <Table
          bordered
          title={() => "Sales"}
          pagination={{
            pageSize: 50,
          }}
          scroll={{
            x: 900,
            y: 240,
          }}
          dataSource={sales}
          columns={[
            { title: "Date", dataIndex: "rf_date", key: "date" },
            {
              title: "Seller Name",
              dataIndex: ["seller", "name"],
              key: "sellerName",
            },
            {
              title: "Sales Manager",
              dataIndex: ["sales_manager", "name"],
              key: "sellerName",
            },
            {
              title: "Sales Director",
              dataIndex: ["sales_director", "name"],
              key: "sellerName",
            },
            {
              title: "Client's Name",
              dataIndex: "client_name",
              key: "clientName",
            },
            {
              title: "Project",
              dataIndex: "project",
              key: "project",
            },
            { title: "Tower", dataIndex: "tower", key: "tower" },
            {
              title: "Block/Floor Number",
              dataIndex: "floor_number",
              key: "blockFloorNumber",
            },
            { title: "Unit", dataIndex: "unit_number", key: "unit" },
            { title: "Status", dataIndex: "status", key: "status" },
          ]}
        />
        <Divider dashed />
        <Table
          bordered
          title={() => "Appointments"}
          pagination={{
            pageSize: 50,
          }}
          scroll={{
            x: 900,
            y: 240,
          }}
          dataSource={appointments}
          columns={[
            { title: "Date", dataIndex: "date", key: "date" },
            {
              title: "Seller Name",
              dataIndex: ["seller", "name"],
              key: "sellerName",
            },
            {
              title: "Sales Manager",
              dataIndex: ["sales_manager", "name"],
              key: "sellerName",
            },
            {
              title: "Sales Director",
              dataIndex: ["sales_director", "name"],
              key: "sellerName",
            },
            {
              title: "Client's Name",
              dataIndex: "clients_name",
              key: "clientName",
            },
            {
              title: "Project",
              dataIndex: ["project", "name"],
              key: "project",
            },
            {
              title: "Tower",
              dataIndex: ["tower", "phase_tower"],
              key: "tower",
            },
            {
              title: "Block/Floor Number",
              dataIndex: "block_floornumber",
              key: "blockFloorNumber",
            },
            { title: "Unit", dataIndex: "unit", key: "unit" },
            { title: "Status", dataIndex: "status", key: "status" },
          ]}
        />
      </Modal>
    </div>
  );
};

export default DashboardPage;
