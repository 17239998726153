import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';

const monthMapping = {
  'January': 1,
  'February': 2,
  'March': 3,
  'April': 4,
  'May': 5,
  'June': 6,
  'July': 7,
  'August': 8,
  'September': 9,
  'October': 10,
  'November': 11,
  'December': 12,
};

const BarGraph = ({ data, isSeller, selectedManager }) => {
  const formatData = (data) => {
    if (isSeller) {
      const formattedData = data
        .map((item) => ({
          ...item,
          monthValue: monthMapping[item.month],
          label: `${item.month} ${item.year}`,
        }))
        .sort((a, b) => {
          if (a.year === b.year) {
            return a.monthValue - b.monthValue;
          }
          return a.year - b.year;
        });

      console.log('Formatted Data:', formattedData);
      return formattedData;
    }

    console.log('Data:', data);
    return data;
  };

  const getDataForSelectedManager = () => {
    if (selectedManager && selectedManager.sellers) {
      return selectedManager.sellers.map((seller) => ({
        name: seller.name,
        sales_count: seller.sales_count,
      }));
    }
    return data;
  };

  const formattedData = formatData(getDataForSelectedManager());
  console.log('Formatted Data (after formatData):', formattedData);

  return (
    <BarChart
      width={800}
      height={400}
      data={formattedData}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={isSeller ? 'label' : 'name'} />
      <YAxis>
        <Label value="Number of Units Sold" angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} />
      </YAxis>
      <Tooltip />
      <Legend />
      <Bar
        dataKey={isSeller ? 'totalUnitsSold' : 'sales_count'}
        fill="#8884d8"
        label={isSeller ? 'Total Units Sold' : 'Units Sold'}
      />
    </BarChart>
  );
};

export default BarGraph;
