import React, { useState, useEffect, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tabs, Card, Form, DatePicker, message, Row, Col } from 'antd';
import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import axios from 'axios';
import moment from 'moment';
import "../index.css";
import { Header } from '../components/common/';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const { TabPane } = Tabs;

const CommissionsPage = () => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [commissionsData, setCommissionsData] = useState([]);
    const [totalGrossAmount, setTotalGrossAmount] = useState(0);
    const [totalNetAmount, setTotalNetAmount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [dataNotFound, setDataNotFound] = useState(false);
    const navigate = useNavigate();
    const searchInput = useRef(null);
    const [commission, setCommission] = useState({
        seller: '',
        client: '',
        unitNo: '',
        project: '',
        dateTo: null,
        dateFrom: null,
    });
    
    const [totalNetReceived, setTotalNetReceived] = useState(0);
    const [salesData, setSalesData] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    const [totalReceivables, setTotalReceivables] = useState(0);
    const [withholdingTax, setWithholdingTax] = useState(0);
    const [netReceivables, setNetReceivables] = useState(0);
    const [tempDateFilteredCommissionsData, setTempDateFilteredCommissionsData] = useState([]);
    const [totalNetAmountInRange, setTotalNetAmountInRange] = useState(0);


    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/login');
        } else {
            fetchCommissionsData();
        }
    }, []);

   

    const resetSearch = () => {
        setCommission({
          seller: '',
          client: '',
          unitNo: '',
          project: '',
          dateTo: null,
          dateFrom: null,
        });
      };

      const fetchCommissionsData = async () => {
        try {
          const storedToken = localStorage.getItem('token');
      
          if (!storedToken) {
            console.error('Token is not present in localStorage. Redirect or show an error message.');
            return;
          }
      
          const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          };
      
          const response = await axios.get(`${API_BASE_URL}/commissions`, { headers });
      
          if (response.data.error) {
            console.error('Error from API:', response.data.error);
            return;
          }
      
          const updatedData = response.data.data.map((item) => {
            const totalReceived =
              item.withholding_tax +
              item.cash_advance +
              item.comm_returned +
              item.comm_offset +
              item.other_deduction;
            return { ...item, totalReceived };
          });
      
          const salesData = response.data.sales;
          const userDetails = {
            userID: response.data.userID,
            userName: response.data.userName,
            userRole: response.data.userRole,
          };


          const commissionData = response.data.data;

          resetSearch();
          setCommissionsData(updatedData);
          setTempDateFilteredCommissionsData(updatedData);
          setSalesData(salesData);
          calculateTotalAmounts(updatedData, salesData, userDetails);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

      
      const calculateTotalAmounts = (commissionData, salesData, userDetails) => {
        
        let totalGross = 0;
        if (salesData.length > 0) {
          totalGross = salesData.reduce((acc, sale) => {
            const netSellingPrice = parseFloat(sale.net_selling_price);
            return isNaN(netSellingPrice) ? acc : acc + netSellingPrice;
          }, 0);
        }
        
        const totalNet = commissionData.reduce((acc, item) => acc + parseFloat(item.net_amount), 0);
       
        
      
        let totalReceivables = 0;
        const authenticatedUserId = userDetails.userID;
        const authenticatedUserRole = userDetails.userRole;
        
        // Iterate through each sale
        salesData.forEach((sale) => {
          // Check if the authenticated user is a seller
          if (sale.seller_id === authenticatedUserId) {
            const receivablesPc = parseFloat(sale.receivables_pc);
            if (isNaN(receivablesPc)) {
              
            } else {
              totalReceivables += receivablesPc;
            }
          }
          // Check if the authenticated user is a sales manager
          else if (sale.sales_manager_id === authenticatedUserId) {
            const receivablesSm = parseFloat(sale.receivables_sm);
            if (isNaN(receivablesSm)) {
              
            } else {
              totalReceivables += receivablesSm;
            }
          }
          // Check if the authenticated user is a sales director
          else if (sale.sales_director_id === authenticatedUserId) {
            const receivablesSd = parseFloat(sale.receivables_sd);
            if (isNaN(receivablesSd)) {
              
            } else {
              totalReceivables += receivablesSd;
            }
          }
          // Check if the authenticated user is a division head
          else if (authenticatedUserRole === 'VP') {
            const receivablesVp = parseFloat(sale.receivables_vp);
            if (isNaN(receivablesVp)) {
              
            } else {
              totalReceivables += receivablesVp;
            }
          }
        });
      
        const totalNetReceived = totalReceivables * 0.9 - totalNet;
        setTotalGrossAmount(totalGross);
        setTotalNetAmount(totalNet);
        setTotalNetReceived(totalNetReceived);
        setTotalReceivables(totalReceivables);
        setWithholdingTax(withholdingTax);
        setNetReceivables(netReceivables);
      };

      const exportToExcel = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const fileName = 'commissions_data';

        const ws = XLSX.utils.json_to_sheet(commissionsData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName + fileExtension;
        link.click();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={(node) => {
                        searchInput.current = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'Released',
            dataIndex: 'released_date',
            key: 'released_date',
            width: '15%',
        },
        {
            title: 'Project',
            dataIndex: 'project',
            key: 'project',
            width: '15%',
            filterMultiple: false,
            ...getColumnSearchProps('project'),
        },
        {
            title: 'Unit',
            dataIndex: 'unit_no',
            key: 'unit_no',
            width: '15%',
        },
        {
            title: 'Client',
            dataIndex: 'client',
            key: 'client',
            width: '10%',
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Gross Amount',
            dataIndex: 'gross_amount',
            key: 'gross_amount',
            width: '10%',
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => a.name.length - b.name.length,
            render: (data) => data.toLocaleString(),
        },
        {
            title: 'Withholding Tax',
            dataIndex: 'withholding_tax',
            key: 'withholding_tax',
            width: '10%',
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => a.name.length - b.name.length,
            render: (data) => data.toLocaleString(),
        },
        {
            title: 'Cash Advance',
            dataIndex: 'cash_advance',
            key: 'cash_advance',
            width: '10%',
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => a.name.length - b.name.length,
            render: (data) => data.toLocaleString(),
        },
        {
            title: 'Commission Returned',
            dataIndex: 'comm_returned',
            key: 'comm_returned',
            width: '10%',
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => a.name.length - b.name.length,
            render: (data) => data.toLocaleString(),
        },
        {
            title: 'Commission Offset',
            dataIndex: 'comm_offset',
            key: 'comm_offset',
            width: '10%',
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => a.name.length - b.name.length,
            render: (data) => data.toLocaleString(),
        },
        {
            title: 'Other Deduction',
            dataIndex: 'other_deduction',
            key: 'other_deduction',
            width: '10%',
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => a.name.length - b.name.length,
            render: (data) => data.toLocaleString(),
        },
        {
            title: 'Net Amount',
            dataIndex: 'net_amount',
            key: 'net_amount',
            width: '10%',
            onFilter: (value, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => a.name.length - b.name.length,
            render: (data) => data.toLocaleString(),
        },
        {
            title: 'Particular Release',
            dataIndex: 'particular_release',
            key: 'particular_release',
            width: '15%',
        },
    ];


    const handleInputChange = (e, field) => {
        const { value } = e.target;
        setCommission((prevCommission) => ({ ...prevCommission, [field]: value }));
    };

    const handleDateChange = (date, dateString, field) => {
        setCommission((prevCommission) => ({ ...prevCommission, [field]: date }));
    };


    const handleSearch = async () => {
        try {
          setLoading(true);
      
          const storedToken = localStorage.getItem('token');
      
          if (!storedToken) {
            console.error('Token is not present in localStorage. Redirect or show an error message.');
            return;
          }
      
          const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          };
      
          const response = await axios.get(`${API_BASE_URL}/commissions`, { headers });
      
          if (response.data.error) {
            console.error('Error from API:', response.data.error);
            return;
          }
      
          const updatedData = response.data.data.map((item) => {
            const totalReceived =
              item.withholding_tax +
              item.cash_advance +
              item.comm_returned +
              item.comm_offset +
              item.other_deduction;
            return { ...item, totalReceived };
          });
      
          const salesData = response.data.sales;
          const userDetails = {
            userID: response.data.userID,
            userName: response.data.userName,
            userRole: response.data.userRole,
          };
      
          // Apply filtering based on commission state
          let filteredCommissionsData = [...updatedData];
          let filteredSalesData = [...salesData];
          let tempDateFilteredCommissionsData = [...updatedData];

          // Check if commissionsData and salesData are not empty before filtering
          if (updatedData.length > 0 && salesData.length > 0) {
        
      
            if (commission.client) {
              filteredCommissionsData = filteredCommissionsData.filter(
                (item) =>
                  (item.client ? item.client.toLowerCase() : '').includes(
                    commission.client.toLowerCase()
                  )
              );
              filteredSalesData = filteredSalesData.filter(
                (item) =>
                  (item.client_name ? item.client_name.toLowerCase() : '').includes(
                    commission.client.toLowerCase()
                  )
              );
            }
      
            if (commission.unitNo) {
              filteredCommissionsData = filteredCommissionsData.filter(
                (item) =>
                  (item.unit_no ? item.unit_no.toLowerCase() : '') ===
                  commission.unitNo.toLowerCase()
              );
              filteredSalesData = filteredSalesData.filter(
                (item) =>
                  (item.unit_number ? item.unit_number.toLowerCase() : '') ===
                  commission.unitNo.toLowerCase()
              );
            }
      
            if (commission.project) {
              filteredCommissionsData = filteredCommissionsData.filter(
                (item) =>
                  (item.project ? item.project.toLowerCase() : '') ===
                  commission.project.toLowerCase()
              );
              filteredSalesData = filteredSalesData.filter((sale) => {
                const commissionProject = commission.project.toLowerCase();
                const commissionProjectParts = commissionProject.split(' ');
                const commissionProjectName = commissionProjectParts[0];
                const commissionTowerName = commissionProjectParts.slice(1).join(' ');
                return (
                  (sale.project ? sale.project.toLowerCase() : '') ===
                    commissionProjectName &&
                  (sale.tower ? sale.tower.toLowerCase() : '') === commissionTowerName
                );
              });
            }
      
            // Filter by released_date range
            if (commission.dateFrom && commission.dateTo) {
                const fromDate = new Date(commission.dateFrom);
                const toDate = new Date(commission.dateTo);
      
                tempDateFilteredCommissionsData = tempDateFilteredCommissionsData.filter(
                  (item) => {
                    const itemDate = new Date(item.released_date);
                    return itemDate >= fromDate && itemDate <= toDate;
                  }
                );
                console.log('Filtered Data:', filteredCommissionsData);
                // Calculate the sum of net_amount within the date range
                const totalNetAmount = tempDateFilteredCommissionsData.reduce(
                  (sum, item) => sum + parseFloat(item.net_amount),
                  0
                );
                setTotalNetAmountInRange(totalNetAmount);
              } else if (commission.dateFrom) {
                const fromDate = new Date(commission.dateFrom);
      
                tempDateFilteredCommissionsData = tempDateFilteredCommissionsData.filter(
                  (item) => {
                    const itemDate = new Date(item.released_date);
                    return itemDate >= fromDate;
                  }
                );
      
                // Calculate the sum of net_amount within the date range
                const totalNetAmount = tempDateFilteredCommissionsData.reduce(
                  (sum, item) => sum + parseFloat(item.net_amount),
                  0
                );
                setTotalNetAmountInRange(totalNetAmount);
              } else if (commission.dateTo) {
                const toDate = new Date(commission.dateTo);
      
                tempDateFilteredCommissionsData = tempDateFilteredCommissionsData.filter(
                  (item) => {
                    const itemDate = new Date(item.released_date);
                    return itemDate <= toDate;
                  }
                );
      
                // Calculate the sum of net_amount within the date range
                const totalNetAmount = tempDateFilteredCommissionsData.reduce(
                  (sum, item) => sum + parseFloat(item.net_amount),
                  0
                );
                setTotalNetAmountInRange(totalNetAmount);
              }
  
            }
            
          
          setCommissionsData(filteredCommissionsData);
          setSalesData(filteredSalesData);
          setTempDateFilteredCommissionsData(tempDateFilteredCommissionsData);
      
          // Recalculate total amounts with filtered data
          calculateTotalAmounts(filteredCommissionsData, filteredSalesData, userDetails);
      
          setLoading(false);
          message.success('Search completed successfully');
        } catch (error) {
          console.error('Error searching commission:', error);
          setLoading(false);
          message.error('Failed to search commission');
        }
      };

    
      
      
      
      


return (
    <div id="body">
      <Header pageName="Commissions" />
    
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
  <div>
    {commission.dateFrom && commission.dateTo && (
      <div style={{ marginBottom: '16px' }}>
        <h3>
          RELEASED FROM {commission.dateFrom.format('MMMM D, YYYY')} TO{' '}
          {commission.dateTo.format('MMMM D, YYYY')}:
        </h3>
        <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
          ₱ {totalNetAmountInRange.toLocaleString()}
        </p>
      </div>
    )}
  </div>

  <Card
    style={{
      textAlign: 'center',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <h2 style={{ marginBottom: '16px' }}>
      <b>PRODUCTION SUMMARY</b>
    </h2>
    <p style={{ marginBottom: '8px' }}>
      TOTAL GROSS SALES: ₱ {totalGrossAmount.toLocaleString()}
    </p>
    <p style={{ marginBottom: '8px' }}>
      PROJECTED GROSS COMMISSION: ₱ {totalReceivables.toLocaleString()}
    </p>
    <p style={{ marginBottom: '8px' }}>
      TOTAL RELEASED: ₱ {totalNetAmount.toLocaleString()}
    </p>
    <p style={{ marginBottom: '8px' }}>
      PROJECTED REMAINING COMMISSION: ₱ {totalNetReceived.toLocaleString()}
    </p>
  </Card>
</div>

    
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Commissions" key="1">
          <Card size="small" style={{ marginBottom: '16px' }}>
          {console.log('commissionsData:', commissionsData)}
            <Button onClick={exportToExcel} style={{ marginBottom: '16px' }}>
              Export to Excel
            </Button>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item label="Seller">
                    <Input
                      value={commission.seller}
                      onChange={(e) => handleInputChange(e, 'seller')}
                      placeholder="Search seller"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Client">
                    <Input
                      value={commission.client}
                      onChange={(e) => handleInputChange(e, 'client')}
                      placeholder="Search client"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Unit No.">
                    <Input
                      value={commission.unitNo}
                      onChange={(e) => handleInputChange(e, 'unitNo')}
                      placeholder="Enter unit number"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Project">
                    <Input
                      value={commission.project}
                      onChange={(e) => handleInputChange(e, 'project')}
                      placeholder="Enter project name"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Released Date From:">
                    <DatePicker
                      value={commission.dateFrom}
                      onChange={(date, dateString) =>
                        handleDateChange(date, dateString, 'dateFrom')
                      }
                      style={{ width: '100%' }}
                      format="YYYY-MM-DD"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Released Date To:">
                    <DatePicker
                      value={commission.dateTo}
                      onChange={(date, dateString) =>
                        handleDateChange(date, dateString, 'dateTo')
                      }
                      style={{ width: '100%' }}
                      format="YYYY-MM-DD"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={handleSearch}>
                  Search
                </Button>
                <Button
                  style={{ marginLeft: '8px' }}
                  onClick={() => {
                    resetSearch();
                    fetchCommissionsData();
                  }}
                >
                  Reset
                </Button>
              </Form.Item>
            </Form>
          </Card>
          {commission.dateFrom && commission.dateTo && totalNetAmountInRange > 0 && (
          <div id="page"> 
          <h2 class="text-gold-h2">Commission Released From {commission.dateFrom.format('MMMM D, YYYY')} To{' '}
          {commission.dateTo.format('MMMM D, YYYY')}:</h2>
          <Card size="small">
            <Table
              rowClassName={() => 'editable-row'}
              bordered
              dataSource={tempDateFilteredCommissionsData}
              columns={columns}
              loading={loading}
              pagination={{ pageSize: 10 }}
              scroll={{ x: 1300 }}
            />
          </Card>
          </div>
        )}
        <div id="page"> 
        <h2 class="text-gold-h2">Commission</h2>
          <Card size="small">
            <Table
              rowClassName={() => 'editable-row'}
              bordered
              dataSource={commissionsData}
              columns={columns}
              loading={loading}
              pagination={{ pageSize: 10 }}
              scroll={{ x: 1300 }}
            />
          </Card>
          </div>
        </TabPane>
    
        {/* <TabPane tab="Search Commissions" key="2">
          <Card size="small" style={{ marginBottom: '16px' }}>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item label="Seller">
                    <Input
                      value={commission.seller}
                      onChange={(e) => handleInputChange(e, 'seller')}
                      placeholder="Search seller"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Client">
                    <Input
                      value={commission.client}
                      onChange={(e) => handleInputChange(e, 'client')}
                      placeholder="Search client"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Unit No.">
                    <Input
                      value={commission.unitNo}
                      onChange={(e) => handleInputChange(e, 'unitNo')}
                      placeholder="Enter unit number"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Project">
                    <Input
                      value={commission.project}
                      onChange={(e) => handleInputChange(e, 'project')}
                      placeholder="Enter project name"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Date From:">
                    <DatePicker
                      value={commission.dateTo}
                      onChange={(date, dateString) =>
                        handleDateChange(date, dateString, 'dateTo')
                      }
                      style={{ width: '100%' }}
                      format="YYYY-MM-DD"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Date To:">
                    <DatePicker
                      value={commission.dateFrom}
                      onChange={(date, dateString) =>
                        handleDateChange(date, dateString, 'dateFrom')
                      }
                      style={{ width: '100%' }}
                      format="YYYY-MM-DD"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={handleSearch}>
                  Search
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </TabPane> */}
      </Tabs>
    </div>
    );
    };
    
    export default CommissionsPage;