import React, { Fragment, useState, useEffect } from "react";
import { Form, Modal, Input, DatePicker, Select, Button, Upload, message } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { useAuth } from "../../services/useAuth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment-timezone';




const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 14,
    },
  },
};

const uploadProps = {
  action: `${API_BASE_URL}/appointments/upload`,
  beforeUpload: (file) => {
    const acceptedFormats = ['image/png', 'image/jpeg', 'image/jpg'];
    const isImage = acceptedFormats.includes(file.type);

    if (!isImage) {
      message.error(`${file.name} is not an image file`);
    }

    return isImage || Upload.LIST_IGNORE;
  },
};

const AddAppointmentModal = ({ isOpen, onClose, projects, towers, onAddSuccess }) => {
  const [form] = Form.useForm();
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTower, setSelectedTower] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const [images, setImages] = useState([]);
  
  const statusOptions = ['Closeable', 'Workable', 'For Booking', 'Fallout'];

  const initialValues = {
    date: moment.tz('Asia/Manila').startOf('day'), // Use moment.tz('Asia/Manila').startOf('day') to get the current date in Manila timezone
    project_id: selectedProject,
  };
  
  

  const handleImageChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-3); // Limit to 3 images
    setImages(fileList);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const dateValue = values.date && values.date.toISOString();
      const storedToken = localStorage.getItem("token");

      if (!storedToken) {
        toast.error("Token is not present in localStorage.", {
          position: 'bottom-center',
          className: 'custom-toast',
          bodyClassName: 'custom-toast-body',
        });
        return;
      }

      const userId = user && user.id;
      const formData = new FormData();
      formData.append('date', dateValue);
      formData.append('clients_name', values.clients_name);
      formData.append('clients_contact_number', values.clients_contact_number || '');
formData.append('clients_email', values.clients_email || '');
      formData.append('block_floornumber', values.block_floornumber);
      formData.append('unit', values.unit);
      formData.append('status', values.status);
      formData.append('project_id', values.project_id);
      if (towers.some(tower => tower.project_id === selectedProject)) {
        formData.append('tower_id', selectedTower ? selectedTower : null);
      }
      formData.append('remarks', values.remarks);

      images.forEach((image, index) => {
        formData.append(`pictures[${index}]`, image.originFileObj);
      });

      const response = await fetch(`${API_BASE_URL}/appointments/create`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const responseData = await response.json();
        if (response.status === 422 && responseData.errors && responseData.errors.combination) {
          const errorMessage = responseData.errors.combination[0];
          toast.error(errorMessage, {
            position: 'bottom-center',
            className: 'custom-toast',
            bodyClassName: 'custom-toast-body',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            pauseOnHover: true,
          });
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } else {
        const responseData = await response.json();
        toast.success(`Appointment is saved successfully`, {
          position: 'bottom-center',
          className: 'custom-toast',
          bodyClassName: 'custom-toast-body',
        });

        form.resetFields();
        onClose();

        if (onAddSuccess) {
          onAddSuccess(); 
        }
      }
    } catch (error) {
      toast.error(`${error.message || 'An unexpected error occurred.'}`, {
        position: 'bottom-center',
        className: 'custom-toast',
        bodyClassName: 'custom-toast-body',
      });
    }
  };

  const handleProjectChange = (value) => {
    setSelectedProject(value);
    setSelectedTower(null); // Reset tower selection on project change
  };

  return (
    <Modal
      title="Add your new appointment"
      open={isOpen}
      onCancel={() => {
        form.resetFields();
        onClose();
        setError(null);
      }}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          OK
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={handleOk}
        initialValues={initialValues}
        {...formItemLayout}
        labelWrap
        style={{
          maxWidth: 600,
          marginTop: "5%",
        }}
        encType="multipart/form-data"
      >
  <Form.Item
          label="Date"
          name="date"
          rules={[
            {
              required: true,
              message: "Date is required.",
            },
          ]}
        >
          <DatePicker
            disabled
            value={moment(initialValues.date)}
          />
        </Form.Item>




        <Form.Item
          label="Client"
          name="clients_name"
          rules={[
            {
              required: true,
              message: "Client is required.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
  label="Contact Number"
  name="clients_contact_number"
>
  <Input />
</Form.Item>
<Form.Item
  label="Email"
  name="clients_email"
>
  <Input />
</Form.Item>

     
        <Form.Item
          label="Project"
          name="project_id"
          rules={[
            {
              required: true,
              message: "Project is required.",
            },
          ]}
        >
          <Select onChange={handleProjectChange}>
            {projects.map((project) => (
              <Select.Option key={project.id} value={project.id}>
                {project.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {selectedProject && towers.some(tower => tower.project_id === selectedProject) && (
          <Form.Item
            label="Tower"
            name="tower_id"
            rules={[
              {
                required: true,
                message: "Tower is required.",
              },
            ]}
          >
            <Select onChange={(value) => setSelectedTower(value)}>
              {towers
                .filter((tower) => tower.project_id === selectedProject)
                .map((tower) => (
                  <Select.Option key={tower.id} value={tower.id}>
                    {tower.phase_tower}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label="Block or Floor Number"
          name="block_floornumber"
          rules={[
            {
              required: true,
              message: "Block or Floor Number is required.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Unit"
          name="unit"
          rules={[
            {
              required: true,
              message: "Unit is required.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Status"
          name="status"
          rules={[
            {
              required: true,
              message: "Status is required.",
            },
          ]}
        >
          <Select onChange={(value) => setSelectedStatus(value)}>
            {statusOptions.map((status) => (
              <Select.Option key={status} value={status}>
                {status}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Remarks"
          name="remarks"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Upload picture"
          name="pictures"
        >
          <Upload
            {...uploadProps}
            listType="picture-card"
            fileList={images}
            onChange={handleImageChange}
            multiple
          >
            {images.length < 3 && <Button icon={<UploadOutlined />}>Upload</Button>}
          </Upload>
        </Form.Item>
      </Form>
      <ToastContainer />
    </Modal>
  );
};

export default AddAppointmentModal;
