import React, { useRef, useState, useEffect } from "react";
import { Spin, Button, Table, Tooltip, Tag, Modal, Form, Input, DatePicker } from 'antd';
import { SearchOutlined, PlusOutlined, LoadingOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAuth } from "../services/useAuth";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import LeaveRequestModal from "../components/common/LeaveRequestModal";
import "../index.css";
import { Header } from "../components/common";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

const AttendancePage = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLeaveRequestModalOpen, setisLeaveRequestModalOpen] = useState(false);
  const { user } = useAuth();
  const [restDayData, setRestDayData] = useState([]);
  const [editingRestDay, setEditingRestDay] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login'); // Redirect to login page
    } else {
      fetchAttendanceData();
    }
  }, [user]);

  const fetchAttendanceData = async () => {
    try {
      const storedToken = localStorage.getItem("token");
  
      if (!storedToken) {
        console.error("Token is not present in localStorage. Redirect or show an error message.");
        return;
      }
  
      const response = await axios.get(`${API_BASE_URL}/attendance`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });
  
      setAttendanceData(response.data.attendance);
      setRestDayData(response.data.restDays);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching attendance data:', error.message);
    }
  };

  const handleEditRestDay = (record) => {
    setEditingRestDay(record);
    setIsEditModalOpen(true);
    form.setFieldsValue({
      date: moment(record.date),
      remarks: record.remarks,
    });
  };

  const handleUpdateRestDay = async (values) => {
    try {
      const storedToken = localStorage.getItem("token");
      const response = await axios.put(
        `${API_BASE_URL}/attendance/rest-days/${editingRestDay.id}`,
        {
          date: values.date.format('YYYY-MM-DD'),
          remarks: values.remarks,
        },
        {
          headers: {
            'Authorization': `Bearer ${storedToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      setIsEditModalOpen(false);
      setEditingRestDay(null);
      fetchAttendanceData(); // Refresh the data after update
      toast.success('Rest day updated successfully');
    } catch (error) {
      console.error('Error updating rest day:', error);
      toast.error('Failed to update rest day');
    }
  };

  const handleDeleteRestDay = async (record) => {
    if (window.confirm(`Are you sure you want to delete this rest day?`)) {
      try {
        const storedToken = localStorage.getItem("token");
        const response = await axios.delete(`${API_BASE_URL}/attendance/rest-days/${record.id}`, {
          headers: {
            'Authorization': `Bearer ${storedToken}`,
          },
        });
  
        fetchAttendanceData(); // Refresh the data after deletion
        toast.success('Rest day deleted successfully');
      } catch (error) {
        console.error('Error deleting rest day:', error);
        toast.error('Failed to delete rest day');
      }
    }
  };

  const columns = [
    {
      title: "Check In Date",
      dataIndex: "check_in_time",
      key: "checkInDate",
      render: (checkInTime) => new Date(checkInTime).toLocaleDateString(),
      sorter: (a, b) => new Date(a.check_in_time) - new Date(b.check_in_time),
      defaultSortOrder: 'descend',
    },
    {
      title: "Check In Time",
      dataIndex: "check_in_time",
      key: "checkInTime",
      render: (checkInTime) => new Date(checkInTime).toLocaleTimeString(),
    },
    {
      title: "Check Out Date",
      dataIndex: "check_out_time",
      key: "checkOutDate",
      render: (checkOutTime) => checkOutTime ? new Date(checkOutTime).toLocaleDateString() : '',
    },
    {
      title: "Check Out Time",
      dataIndex: "check_out_time",
      key: "checkOutTime",
      render: (checkOutTime) => checkOutTime ? new Date(checkOutTime).toLocaleTimeString() : '',
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <span>{status}</span>,
    },
    {
      title: "Location",
      dataIndex: "address",
      key: "address",
      render: (address) => <span>{address}</span>,
    },
  ];

  const restDayColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => moment(date).format('YYYY-MM-DD'),
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <EditOutlined onClick={() => handleEditRestDay(record)} style={{ marginRight: '8px' }} />
          <DeleteOutlined onClick={() => handleDeleteRestDay(record)} />
        </>
      ),
    },
  ];

  return (
    <div id="body">
      <Header pageName="Attendance" />
      <div id="start-end">
        {/* ... */}
      </div>
      <div id="page">
        {loading ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
        ) : (
          <>
            <Table
              rowClassName={() => 'editable-row'}
              bordered
              dataSource={attendanceData}
              columns={columns}
              className="w-100 h-100"
            />
            <h2>Rest Days</h2>

            <Table
              rowKey="id"
              dataSource={restDayData}
              columns={restDayColumns}
            />
          </>
        )}

        <LeaveRequestModal
          isOpen={isLeaveRequestModalOpen}
          onClose={() => setisLeaveRequestModalOpen(false)}
        />

        <Modal
          title="Edit Rest Day"
          open={isEditModalOpen}
          onOk={form.submit}
          onCancel={() => {
            setIsEditModalOpen(false);
            setEditingRestDay(null);
          }}
        >
          <Form form={form} onFinish={handleUpdateRestDay}>
            <Form.Item
              name="date"
              label="Date"
              rules={[{ required: true, message: 'Please select a date' }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="remarks"
              label="Remarks"
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>

        <ToastContainer style={{ fontSize: '14px' }} />
      </div>
    </div>
  );
};

export default AttendancePage;
