import React, { useState } from 'react';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'; // Import Eye icons
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/logo.png';
import { useNavigate } from "react-router-dom";
import axios from 'axios'; // Import axios
import AppURL from '../../api/AppURL'; // Import AppURL

const UserLogin = ({ setUser, user }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const navigate = useNavigate();

  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      localStorage.clear();
      const response = await axios.post(AppURL.UserLogin, { email, password });
      const token = response.data.token;
      localStorage.setItem('token', token);   // Adjust based on your API response structure

      // Redirect to dashboard or perform other actions upon successful login
      navigate('/dashboard');
    } catch (error) {
      console.error('Login Error:', error);
      setError('Invalid login credentials'); // Set the error message
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword); // Toggle the state
  };

  return (
    <>
      <div className='bg-neutral-900 h-screen flex flex-col items-center justify-center'>
        <img src={logo} alt="Prestige Logo" className="mb-10" style={{ height: '160px' }} />
        <form onSubmit={formSubmit}>
          {error && <div className="text-red-600 mb-4">{error}</div>} {/* Display error message */}
          <h2 className='text-4xl dark:text-orange-200 font-semibold text-center text-[#A98F4E] mb-5'> LOGIN PORTAL </h2>

          <div className='flex flex-col text-stone-50 py-2'>
            <input
              className='rounded-lg bg-gray-700'
              type="email"
              placeholder=" Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className='flex flex-col text-stone-50 py-2 relative'>
            <input
              className='rounded-lg bg-gray-700 pr-10' // Add some padding to accommodate the eye icon
              type={showPassword ? "text" : "password"} // Toggle input type based on showPassword state
              placeholder=" Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {showPassword ? (
              <EyeOutlined className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer" onClick={togglePasswordVisibility} /> // Show the visible eye icon
            ) : (
              <EyeInvisibleOutlined className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer" onClick={togglePasswordVisibility} /> // Show the invisible eye icon
            )}
          </div>

          <button type="submit" className='bg-[#A98F4E] text-white rounded-lg w-full my-5 py-2 dark:text-white font-semibold text-center'> Sign In </button>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default UserLogin;
