import React, { useState, useEffect } from 'react';
import { Card, Input, Button, Upload, Form, DatePicker, Spin, Modal, message, Avatar } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import '../styles/ProfilePage.css';
import { Header } from '../components/common/';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_BASE_URL_API = process.env.REACT_APP_API_BASE_URL_API;
const ProfilePage = () => {
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    birthday: null,
    photo: null,
    phone: '',
  });
  const [loading, setLoading] = useState(true);
  const [passwords, setPasswords] = useState({ currentPassword: '', newPassword: '' });
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
    } else {
      fetchProfileData();
    }
  }, []);

  const fetchProfileData = async () => {
    const storedToken = localStorage.getItem('token');


    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storedToken}`,
      };
      const response = await axios.post(`${API_BASE_URL}/profile`, { headers });
      const userData = response.data.user; // Access user data
      setProfile({
        name: userData.name,
        email: userData.email,
        birthday: userData.birthday ? moment(userData.birthday) : null,
        photo: userData.profile_photo_path,
        photo_url: userData.profile_photo_url,
        phone: userData.phone,
      });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching profile data:', error);
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
  };

  const handleDateChange = (date) => {
    setProfile((prevProfile) => ({ ...prevProfile, birthday: date }));
  };

  const handlePhotoChange = async (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      const { response } = info.file;
      if (response && response.url) {
        setProfile((prevProfile) => ({ ...prevProfile, photo: response.url }));
        message.success('Profile photo updated successfully');
      } else {
        message.error('Error uploading photo');
      }
    }
  };

  const handlePasswordChange = () => {
    setIsPasswordModalVisible(true);
  };

  const handlePasswordModalOk = async () => {
    const storedToken = localStorage.getItem('token');
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storedToken}`,
      };
      const passwordData = {
        currentPassword: passwords.currentPassword,
        newPassword: passwords.newPassword,
      };
      await axios.post(`${API_BASE_URL}/change-password`, passwordData, { headers });
      setIsPasswordModalVisible(false);
      message.success('Password changed successfully');
    } catch (error) {
      console.error('Error changing password:', error);
      message.error('Error changing password');
    }
  };

  const handlePasswordModalCancel = () => {
    setIsPasswordModalVisible(false);
  };

  const handleSaveChanges = async () => {
    const storedToken = localStorage.getItem('token');
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storedToken}`,
      };
      const profileData = {
        name: profile.name,
        email: profile.email,
        birthday: profile.birthday ? profile.birthday.format('YYYY-MM-DD') : null,
        photo: profile.photo,
        photo_url:profile.photo_url,
        phone: profile.phone,
      };
     
      const response = await axios.put(`${API_BASE_URL}/profile`, profileData, { headers });

      if (response.status === 200) {
        message.success('Profile updated successfully');
      } else {
        message.error('Error updating profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      message.error('Error updating profile');
    }
  };

  const uploadProps = {
    name: 'photo',
    action: `${API_BASE_URL}/upload-photo`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    onChange: handlePhotoChange,
    showUploadList: false,
    onError: (error) => {
      console.error('Upload error:', error);
      message.error('Error uploading photo');
    },
  };

  return (
    <div id="body">
      <Header pageName="Profile" textColor="#A98F4E" />
      <div id="page">
        <h1
          style={{
            fontSize: '30px',
            color: '#A98F4E',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
        </h1>
        {loading ? (
          <Spin size="large" />
        ) : (
          <Card style={{ maxWidth: 600, margin: '0 auto' }}>
           <Avatar
 src={profile.photo ? `${API_BASE_URL_API}/storage/profile_photos/${profile.photo}` : profile.photo_url}
  size={100}
  style={{ marginBottom: 20 }}
/>
            <Form layout="vertical">
              <Form.Item label="Name">
                <Input value={profile.name} name="name" disabled />
              </Form.Item>
              <Form.Item label="Email">
                <Input value={profile.email} name="email" disabled />
              </Form.Item>
              <Form.Item label="Birthday">
                <DatePicker value={profile.birthday} name="birthday" onChange={handleDateChange} style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item label="Change Profile Picture">
  <Upload {...uploadProps}>
    {profile.photo ? (
      <img src={`${API_BASE_URL_API}/storage/profile_photos/${profile.photo}`} alt="Profile" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
    ) : (
      <Button icon={<UploadOutlined />}>Upload</Button>
    )}
  </Upload>
</Form.Item>
              <Form.Item label="Phone Number">
                <Input value={profile.phone} name="phone" onChange={handleInputChange} />
              </Form.Item>
              <Form.Item style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={handleSaveChanges} style={{ marginRight: '10px' }}>
                  Save Changes
                </Button>
                <Button type="default" onClick={handlePasswordChange}>
                  Change Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        )}
      </div>
      <Modal
  title="Change Password"
  visible={isPasswordModalVisible}
  onOk={handlePasswordModalOk}
  onCancel={handlePasswordModalCancel}
>
  <Form layout="vertical">
    <Form.Item label="Current Password">
      <Input.Password
        value={passwords.currentPassword}
        onChange={(e) => setPasswords({ ...passwords, currentPassword: e.target.value })}
      />
    </Form.Item>
    <Form.Item label="New Password">
      <Input.Password
        value={passwords.newPassword}
        onChange={(e) => setPasswords({ ...passwords, newPassword: e.target.value })}
      />
    </Form.Item>
  </Form>
</Modal>
    </div>
  );
};

export default ProfilePage;
