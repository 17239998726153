import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, TimePicker, DatePicker, message } from 'antd';
import { Header } from "../components/common/"
import { useNavigate } from 'react-router-dom';

import "../index.css"

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }, 
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }, 
  },
};

const OvertimePage = () => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if token is not present, redirect to login page
    if (!localStorage.getItem('token')) {
      navigate('/login'); // Redirect to login page
    } else {
      // Token is present, continue with the component logic
      fetchAppointmentData();
    }
  }, []);

  const fetchAppointmentData = () => {
    const storedToken = localStorage.getItem("token");

      if (!storedToken) {
        console.error("Token is not present in localStorage. Redirect or show an error message.");
        return;
      }
    }

  const onFinish = (values) => {
    setFormData(values);
    message.success('Form submitted successfully!');
  };

  return (
    <div id="body">
        <Header pageName ="Overtime Application Form"/>
        <div id='page'>
          <Form {...formItemLayout} form={form} onFinish={onFinish} style={{ maxWidth: 600 }}>
            <Form.Item
              label="Department"
              name="Department"
              rules={[{ required: true, message: 'Please type your Department' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Date Submitted"
              name="DateSubmitted"
              rules={[{ required: true, message: 'Please input the date!' }]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              label="Shift Schedule"
              name="ShiftSchedule"
              rules={[{ required: true, message: 'Please input the shift schedule!' }]}
            >
              <TimePicker.RangePicker />
            </Form.Item>

            <Form.Item label="Location" name="Location" rules={[{ required: true, message: 'Please type your name!' }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="OT Selection"
              name="OT Selection"
              rules={[{ required: true, message: 'Please input!' }]}
            >
              <Select placeholder="Select an option">
                <Option value="NormalOT">Normal OT</Option>
                <Option value="EarlyOT">EarlyOT</Option>
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
          {/* upload file */}
        </div>
    </div>
  );
};

export default OvertimePage;
