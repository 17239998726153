import React, { useEffect } from 'react';
import { Form, Input, DatePicker, Button, message } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import "../index.css";
import { Header } from "../components/common/";

const RestDayPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if token is not present, redirect to login page
    if (!localStorage.getItem('token')) {
      navigate('/login'); // Redirect to login page
    }
  }, []);

  const onFinish = async (values) => {
    const storedToken = localStorage.getItem("token");

    if (!storedToken) {
      console.error("Token is not present in localStorage. Redirect or show an error message.");
      return;
    }

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const headers = {
      'Authorization': `Bearer ${storedToken}`,
    };

    try {
      // Ensure date is correctly formatted
      const formattedDate = values.date.format('YYYY-MM-DD'); 


      const response = await axios.post(`${API_BASE_URL}/attendance/restday`, {
        date: formattedDate,
        remarks: values.remarks,
      }, { headers });

      if (response.status === 200) {
        message.success('Rest Day submitted successfully!');
        navigate('/attendance'); // Redirect to attendance page after successful submission
      } else {
        message.error(response.data.error || 'Failed to submit Rest Day. Please try again later.');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        message.error(error.response.data.error);
      } else {
        message.error('Failed to submit Rest Day. Please try again later.');
      }
      console.error('Error submitting Rest Day:', error);
    }
  };

  return (
    <div id="body">
      <Header pageName="Rest Day Form" />
      <div id="page">
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            name="date"
            label="Date"
            rules={[{ required: true, message: 'Please select a date' }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item name="remarks" label="Remarks">
            <Input.TextArea />
          </Form.Item>
          <div id='start-end'>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default RestDayPage;
