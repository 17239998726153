import React from 'react';
import { Modal, Form, Input, DatePicker, Select, Button, Upload, message } from "antd";
import { UploadOutlined } from '@ant-design/icons';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const props = {
  name: 'file',
  action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const LeaveRequestModal = ({
  isOpen,
  onClose,
}) => {
  return ( 
    <Modal 
      title="Apply for leave"
      visible={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" htmlType="submit">
          Submit
        </Button>,
      ]}
    >
      <Form
        {...formItemLayout}
        variant="filled"
        style={{
          maxWidth: 600,
          marginTop: '5%',
        }}
      >
        <Form.Item
          label="Description"
          name="Description"
          rules={[{
            required:true,
            message: "Description is required."
          }]}
        >
          <Input/>
        </Form.Item>
              
        <Form.Item
          label="Leave Type"
          name="LeaveType"
          rules={[{
            required:true,
            message: "Leave Type is required."
          }]}
        >
          <Select>
            <Select.Option value="Sick leave">Sick leave</Select.Option>
            <Select.Option value="Casual leave">Casual leave</Select.Option>
            <Select.Option value="Public holiday">Public holiday</Select.Option>
            <Select.Option value="Maternity leave">Maternity leave</Select.Option>
            <Select.Option value="Bereavement">Bereavement</Select.Option>
          </Select>
        </Form.Item>
        
        <Form.Item
          label="Date"
          name="Date"
          rules={[{
            required:true,
            message: "Date is required."
          }]}
        >
          <DatePicker/>
        </Form.Item>

        <Form.Item label="File Attachment">
          <Upload {...props}>
            <Button icon={<UploadOutlined />}> Click to Upload</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default LeaveRequestModal;
