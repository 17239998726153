import React, { useState, useEffect } from "react";
import { Button, Modal, Tooltip, message } from "antd";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import L from "leaflet";
import { EnvironmentOutlined } from "@ant-design/icons";
import "../../index.css";

const CheckOutModal = ({ isOpen, onClose }) => {
  const [currentPosition, setCurrentPosition] = useState(null);
  const [address, setAddress] = useState("");

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (isOpen) {
      getCurrentLocation();
    }
  }, [isOpen]);

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentPosition({ lat: latitude, lng: longitude });
        getAddress(latitude, longitude);
      },
      (error) => {
        console.error("Error getting current location:", error);
      }
    );
  };

  const getAddress = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
      );
      if (response.status === 200) {
        setAddress(response.data.display_name);
      }
    } catch (error) {
      console.error("Error getting address:", error);
    }
  };

  const handleOk = async () => {
    if (!currentPosition) return;

    try {
      const response = await axios.post(`${API_BASE_URL}/api/location`, {
        latitude: currentPosition.lat,
        longitude: currentPosition.lng,
        address,
      });
      if (response.status === 200) {
        console.log("Location saved successfully!");
        onClose(); // Close the modal after successful save
      }
    } catch (error) {
      console.error("Error saving location:", error);
    }
  };

  const pinIcon = L.divIcon({
    html: `<div style="color: #52c41a;"><EnvironmentOutlined /></div>`,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  const handleConfirmLocation = async () => {
    try {
      if (!currentPosition) {
        message.error("Current position not available");
        return;
      }

      const response = await axios.post(`${API_BASE_URL}/check-out`, {
        latitude: currentPosition.lat,
        longitude: currentPosition.lng,
      });

      const { status, message: confirmationMessage } = response.data;

      message.success(confirmationMessage);

      handleOk(); // Close the modal after successful check-in
      onClose(); // Close the modal after successful check-in
    } catch (error) {
      message.error("Error during check-in. Please try again.");
    }
  };

  const markerIcon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
  });

  return (
    <Modal
      title="Check your location"
      visible={isOpen}
      onCancel={onClose}
      footer={[
        <Tooltip title="Retake your location?" key="refresh">
          <Button type="link" onClick={getCurrentLocation}>
            Refresh
          </Button>
        </Tooltip>,
        <Button key="back" type="secondary" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleConfirmLocation}>
          Confirm
        </Button>,
      ]}
    >
      <p>Confirm location?</p>
      {currentPosition && (
        <div id="map-container">
          <MapContainer center={currentPosition} zoom={15} style={{ width: "100%", height: "400px" }}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={currentPosition} icon={markerIcon}>
              <Popup>{address}</Popup>
            </Marker>
          </MapContainer>
        </div>
      )}
      <p>{address}</p>
    </Modal>
  );
};

export default CheckOutModal;