import React, { useState, useEffect } from "react";
import { Button, Modal, Tooltip, message, Alert, Spin } from "antd";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import L from "leaflet";
import { EnvironmentOutlined } from "@ant-design/icons";
import "../../index.css";
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

const CheckInModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [currentPosition, setCurrentPosition] = useState(null);
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (isOpen) {
      getCurrentLocation();
    }
  }, [isOpen]);

  // const getCurrentLocation = () => {
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       const { latitude, longitude } = position.coords;
  //       setCurrentPosition({ lat: latitude, lng: longitude });
  //       getAddress(latitude, longitude);
  //     },
  //     (error) => { 
  //       console.error("Error getting current location:", error);
  //     }
  //   );
  // };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentPosition({ lat: latitude, lng: longitude });
          getAddress(latitude, longitude);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              <Alert message="User denied the request for Geolocation." type="error" />
              break;
            case error.POSITION_UNAVAILABLE:
              <Alert message="Location information is unavailable." type="error" />
              break;
            case error.TIMEOUT:
              <Alert message="The request to get user location timed out." type="error" />

              break;
            case error.UNKNOWN_ERROR:
              <Alert message="An unknown error occurred." type="error" />

              break;
          }
        },
        { timeout: 10000 } // Set timeout to 10 seconds
      );
    } else {
      <Alert message="Geolocation is not supported by this browser." type="error" />

    }
  };  

  const getAddress = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
      );
      if (response.status === 200) {
        setAddress(response.data.display_name);
      }
    } catch (error) {
      console.error("Error getting address:", error);
    }
  };


  const handleOk = async () => {
    if (!currentPosition) return;

    try {
      const response = await axios.post(`${API_BASE_URL}/location`, {
        latitude: currentPosition.lat,
        longitude: currentPosition.lng,
        address,
      });
      if (response.status === 200) {
        console.log("Location saved successfully!");
        onClose(); // Close the modal after successful save
      }
    } catch (error) {
      console.error("Error saving location:", error);
    }
  };


  const handleConfirmLocation = async () => {
    setLoading(true);
    try {
      if (!currentPosition) {
        message.error("Current position not available");
        setLoading(false);
        return;
      }
  
      const currentTimeInManila = moment().tz('Asia/Manila').format('HH:mm:ss');
  
      const response = await axios.post(`${API_BASE_URL}/check-in`, {
        latitude: currentPosition.lat,
        longitude: currentPosition.lng,
        timestamp: currentTimeInManila,
        address, // Send the address as well
      });
  
      const { status, message: confirmationMessage } = response.data;
  
      message.success(confirmationMessage);
  
      if (status === 'Late') {
        message.warning("You're late!");
      } else if (status === 'No Record Found') {
        message.info("No record found for this time");
      }
  
      handleOk(); // Close the modal after successful check-in
      onClose(); // Close the modal after successful check-in
      navigate('/dashboard');
    } catch (error) {
      message.error("Error during check-in. Please try again.");
      setLoading(false);
    }
  };

  const markerIcon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
  });

  return (
    <Modal
      title="Check your location"
      visible={isOpen}
      onCancel={onClose}
      footer={[
        <Tooltip title="Retake your location?" key="refresh">
          <Button type="link" onClick={getCurrentLocation}>
            Refresh
          </Button>
        </Tooltip>,
        <Button key="back" type="secondary" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleConfirmLocation}>
          Confirm
        </Button>,
        loading && <Spin size="large" />
      ]}
    >
      <p>Confirm location?</p>
      {currentPosition && (
        <div id="map-container">
          <MapContainer center={currentPosition} zoom={15} style={{ width: "100%", height: "400px" }}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={currentPosition} icon={markerIcon}>
              <Popup>{address}</Popup>
            </Marker>
          </MapContainer>
        </div>
      )}
      <p>{address}</p>
    </Modal>
  );
};

export default CheckInModal;