import React, { useState, useEffect } from 'react';
import { Button, Form, Input, TimePicker, DatePicker, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'; 
import { Header } from '../components/common';
import { useNavigate } from 'react-router-dom';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const props = {
  name: 'file',
  action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const OfficialBusinessPage = () => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if token is not present, redirect to login page
    if (!localStorage.getItem('token')) {
      navigate('/login'); // Redirect to login page
    } else {
      // Token is present, continue with the component logic
      fetchAppointmentData();
    }
  }, []);

  const fetchAppointmentData = () => {
    const storedToken = localStorage.getItem("token");

      if (!storedToken) {
        console.error("Token is not present in localStorage. Redirect or show an error message.");
        return;
      }
    }

  const onFinish = (values) => {
    setFormData(values);
    message.success('Form submitted successfully!');
  };

  return (
    <div id="body">
      <Header pageName="Official Business Form"/>
      <div id='page'>
      <Form {...formItemLayout} form={form} onFinish={onFinish} style={{ maxWidth: 600 }} labelWrap>
        <Form.Item 
        label="Date From"
        name="dateFrom" 
        > 
        <DatePicker />
        </Form.Item>

        <Form.Item
          label="Date To"
          name="dateTo"
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          label="Time From"
          name="timeFrom"
        >
          <TimePicker />
        </Form.Item>

        <Form.Item
          label="Time To"
          name="timeTo"
        >
          <TimePicker />
        </Form.Item>

        <Form.Item
          label="Location"
          name="location"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Reason"
          name="reason"
        >
          <Input />
        </Form.Item>

        <Form.Item 
          label="Attach supporting document"
          name="document"
        >
          <Upload {...props}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
          <div style={{ marginRight: '8px', display: 'inline-block' }}>
            <Button type="primary" htmlType="submit">
              Proceed
            </Button>
          </div>

          <Link to="/overtime">
            <Button>
              Back
            </Button>
          </Link>
        </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default OfficialBusinessPage;
