import React, { useEffect, useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'antd'; // Import Button from Ant Design
import { Header } from "../components/common";
import axios from 'axios';

const CheckinPage = () => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [map, setMap] = useState(null);
  const mapContainerRef = useRef(null);

  useEffect(() => {
    getCurrentLocation();
  }, []);

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { coords } = position;
        setCurrentLocation(coords);
      },
      (error) => {
        console.error('Error getting user location:', error);
        toast.error('Error getting user location. Please enable location services and try again.', {
          position: 'top-right',
        });
      }
    );
  };

  useEffect(() => {
    if (currentLocation) {
      loadGoogleMaps();
    }
  }, [currentLocation]);

  const loadGoogleMaps = () => {
    const googleMapsScript = document.createElement('script');
    googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAWRmeVXtooUVI5y2dec_E7OlaXwSxxOBE&libraries=places`; // Replace YOUR_API_KEY with your actual Google Maps API key
    googleMapsScript.onload = initMap;
    googleMapsScript.onerror = () => {
      toast.error('Error loading Google Maps. Please check your API key and try again.', {
        position: 'top-right',
      });
    };
    document.body.appendChild(googleMapsScript);
  };

  const initMap = () => {
    const googleMap = new window.google.maps.Map(mapContainerRef.current, {
      center: { lat: currentLocation.latitude, lng: currentLocation.longitude },
      zoom: 15,
    });
    setMap(googleMap);
    new window.google.maps.Marker({
      position: { lat: currentLocation.latitude, lng: currentLocation.longitude },
      map: googleMap,
    });
  };

  const handleCheckIn = () => {
    if (!currentLocation) {
      toast.error('Unable to check in. Location information is not available.', { position: 'top-right' });
      return;
    }
  
    // Here, you can use the currentLocation object for the check-in process
    // For example, you can send a request to your backend API to store the check-in information along with the detected location
    axios.post('/api/checkin', { location: currentLocation })
      .then(response => {
        toast.success('Checked in successfully!', { position: 'top-right' });
      })
      .catch(error => {
        // Handle error
        toast.error('Error checking in. Please try again.', { position: 'top-right' });
      });
  };

  const handleCheckOut = () => {
    if (!currentLocation) {
      toast.error('Unable to check out. Location information is not available.', { position: 'top-right' });
      return;
    }
  
    // Here, you can use the currentLocation object for the check-out process
    // For example, you can send a request to your backend API to store the check-out information along with the detected location
    axios.post('/api/checkout', { location: currentLocation })
      .then(response => {
        toast.success('Checked out successfully!', { position: 'top-right' });
      })
      .catch(error => {
        // Handle error
        toast.error('Error checking out. Please try again.', { position: 'top-right' });
      });
  };
  
  return (
    <div id='body'>
      <Header pageName="Check In"/>
      <div id="page">
        <div ref={mapContainerRef} style={{ width: '100%', height: '700px' }}></div>
        <Button type="primary" onClick={handleCheckIn} style={{ marginTop: '20px', marginRight: '10px' }}>Check In</Button>
        <Button type="primary" onClick={handleCheckOut} style={{ marginTop: '20px' }}>Check Out</Button>
      </div>
    </div>
  );
};

export default CheckinPage;
